import React, { useEffect, useRef, useState } from 'react';
import { observer } from "mobx-react-lite";

//Стили
import '../css/banners.css';

const Banners = observer(({ }) => {
    return (
        <>
            <div className='banner_item'>
                <img src='/images/bonus.png' className='banner_img' />
            </div>
        </>
    );
});

export default Banners;

import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { observer } from "mobx-react-lite";
import { Routes, Route, Link, useNavigate } from 'react-router-dom';

//Конфиг
import config from '../config';

//Стили
import '../css/mine.css';
import '../css/task.css';

//Языки / переводы / сторы
import translationStore from '../locales/locales';
import PlayerStore from '../store/playerStore';
import TasksStore from '../store/tasksStore';


//Компоненты
import TopHeader from '../components/TopHeader';
import SlideBottomPanel from '../components/SlideBottomPanel';
import TemplateTask from '../components/TemplateTask';

//Toast сообщения
import { ToastContainer, toast, Bounce, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const Mine = observer(() => {

    //Языки / переводы
    const tr = translationStore.trnsl;

    const bybitTasks = [
        {
            "id": 1,
            "title_ru": "Подпишись на Telegram-канал ByBit",
            "title_en": "Subscribe to the ByBit Telegram channel",
            "description_ru": "Пройдите по ссылке, нажав на кнопку подписаться. Подпишитесь на канал, после проверки Вам начислят вознаграждение",
            "description_en": "Follow the link by clicking on the subscribe button. Subscribe to the channel, after verification you will receive a reward",
            "image": '/images/bybiytelegram.png',
            "weblink": null,
            "task_id": 7,
            "awardspins": 0,
            "awardmonets": 100000,
            "check": 0,
        },
        {
            "id": 2,
            "title_ru": "Заполни анкету и стань парнером ByBit",
            "title_en": "Fill out the form and become a ByBit partner",
            "description_ru": "Заполни анкету и стань нашим партнером",
            "description_en": "Fill out the form and become our partner",
            "image": '/images/bybit_icon.png',
            "weblink": 'https://docs.google.com/forms/d/e/1FAIpQLSebtBdDuorg6cnEq54RDvjlk-s0AGPt8IOik8GbyavjTbDtXQ/viewform',
            "task_id": 0,
            "awardspins": 0,
            "awardmonets": 0,
            "сheck": 0,
        },

    ];

    ////Для всплывающей панели  SlideBottomPanel=======
    const [isPanelOpen, setPanelOpen] = useState(false);
    const openPanel = () => {
        setPanelOpen(true);
    };

    const closePanel = () => {
        setPanelOpen(false);
    };
    ///=======

    const [task, setTask] = useState(null); //Стейт который будет отображатся в сплывающей панеле 
    const handleTaskClick = (task) => {
        console.log("Клик по задаче:", JSON.stringify(task, null, 1));
        setTask(task);
        openPanel();
    };

    // Инициализация хука useNavigate для навигации
    const navigate = useNavigate();

    //функция для открытия и прохождения таска из TasksStore
    const hangleTaskReal = (id) => {
        console.log("Клик по задаче:", id);

        setTask(TasksStore.tasks.find(task => task.id === id));
        openPanel();
        console.log('Открыть задание: ', JSON.stringify(TasksStore.tasks.find(task => task.id === id), null, 1))
    };

    //Запускаем функцию taskList из стора, которая берет список заданий с сервера
    useEffect(() => {
        // Вызов taskList независимо от того, смонтирован ли Tasks.js
        TasksStore.taskList();
    }, []);

    //Для формы отправики BID
    const [inputValue, setInputValue] = useState('');
    //const [formData, setFormData] = useState(null);

    const handleSubmit = (event) => {
        event.preventDefault();
        //setFormData(inputValue); // Записываем данные из поля ввода в состояние
        //alert(`Данные сохранены: ${inputValue}`);
        TasksStore.submitForm(inputValue, task.id);
    };

    //Для обновления стейта task из стора TasksStore
    const handleTaskUpdate = (task) => {
        console.log("Обновление задачи:", task);
        setTask(task);
        openPanel();
    };
    useEffect(() => {
        // Устанавливаем callback для обновления задачи
        TasksStore.setTaskUpdateCallback(handleTaskUpdate);

        // Вызываем taskList() чтобы инициировать загрузку задач
        TasksStore.taskList();

        // Очистка эффекта
        return () => {
            TasksStore.setTaskUpdateCallback(null);
        };
    }, []);

    return (
        <>
            <div className='slot_machine_flex_top'>
                <TopHeader />
            </div>

            <div className='mine_main'>
                <div className='mine_mainwrap'>

                    <div className='bybit_image_main'>
                        <img src="/images/bibitmain.png" style={{ width: '100%', height: 'auto' }} />
                    </div>


                    <div className='listTaskBybit'>
                        {
                            bybitTasks?.map(taskBybit => (
                                <div className='taskBybit_item' key={taskBybit.id}>
                                    <button
                                        onClick={() => {
                                            if (taskBybit.task_id !== 0) {
                                                hangleTaskReal(taskBybit.task_id);
                                            } else if (taskBybit.task_id === 0) {
                                                handleTaskClick(taskBybit);
                                            } else {
                                                navigate(taskBybit.link);
                                            }
                                        }}
                                        className="buttonTransparent"
                                    >
                                        <div className='taskBybit_item_row'>
                                            <div className='taskBybit_item_col'>
                                                <div className='taskBybit_title'>
                                                    {tr.user_lang === 'ru' ? taskBybit.title_ru : taskBybit.title_en}
                                                </div>
                                            </div>
                                            <div className='taskBybit_item_col'>
                                                <div className='tastImageWrap'>
                                                    <img
                                                        src={taskBybit.image}
                                                        style={taskBybit.link ? { width: '105px', height: '82px' } : { width: '82px', height: '82px' }}
                                                        alt={taskBybit.title_en || taskBybit.title_ru}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </button>
                                </div>
                            ))
                        }
                    </div>

                </div>
            </div>


            {/* ЗАДАНИЯ БАЙБИТА */}
            {task ? (
                <SlideBottomPanel isOpen={isPanelOpen} onClose={closePanel}>
                    <div className='task_bottom_panel_wrap'>
                        <div className='task_bottom_panel_image'>
                            <img src={task.image} style={{ width: '80px', height: 'auto' }} />
                        </div>
                        <div className='task_bottom_panel_title'>
                            {tr.user_lang == 'ru' ? task.title : task.title_en}
                        </div>

                        <div className='task_bottom_panel_description'>
                            {tr.user_lang == 'ru' ? task.description : task.description_en}
                        </div>

                        <div className='task_bottom_panel_awards'>
                            {task.awardspins != '0' ? (
                                <div className='task_bottom_panel_awards_spin'>
                                    +{task.awardspins} scrolls
                                </div>
                            ) : null}
                            {task.awardmonets != '0' ? (
                                <div className='task_bottom_panel_awards_monets'>
                                    +{task.awardmonets} <img src="/images/moneta.png" style={{ width: '40px', height: '30px', verticalAlign: 'bottom' }} />
                                </div>
                            ) : null}
                        </div>

                        {task.type == 'confirmation' || task.type == 'without_confirmation' ? (
                            <>
                                <div className='task_bottom_panel_button'>
                                    <a href={task.link} rel="noopener noreferrer" target="_blank" className="button">
                                        {tr[tr.user_lang]['subscribe']}
                                    </a>
                                </div>

                                <div className='task_bottom_panel_button'>
                                    {task.check && task.completed_by_current_user === true ? (
                                        <button onClick={() => console.log('Пройдено!')} className="buttonBigGreen">
                                            {tr[tr.user_lang]['passed']}
                                        </button>
                                    ) : task.check ? (
                                        <button onClick={() => TasksStore.telegramConfirm(task.id, task.check)} className="buttonBig">
                                            {tr[tr.user_lang]['check']}
                                        </button>
                                    ) : null}
                                </div>

                                <div className='task_bottom_panel_button'>
                                    {!task.check && task.collect == 0 ? (
                                        <button onClick={() => TasksStore.taskConfirm(task.id)} className="buttonBig">
                                            {tr[tr.user_lang]['check']}
                                        </button>
                                    ) : !task.check && task.completed_by_current_user === true && task.is_check_time_in_future === true && task.collect == 1 || !task.check && task.collect == 1 && task.later == 1 ? (
                                        <button onClick={() => TasksStore.taskCollectReward(task.id)} className="buttonBig">
                                            Collect the reward later
                                        </button>
                                    ) : !task.check && task.is_check_time_in_future === false && task.collect == 1 ? (
                                        <button onClick={() => TasksStore.taskCollectReward(task.id)} className="buttonBig">
                                            To collect the reward
                                        </button>
                                    ) : !task.check && task.collect == 2 ? (
                                        <button onClick={() => console.log('Success!')} className="buttonBigGreen">
                                            {tr[tr.user_lang]['passed']}
                                        </button>
                                    ) : null}
                                </div>
                            </>
                        ) : null}

                        {task.weblink ? (
                            <div className='task_bottom_panel_button'>
                                {console.log('task.link ====>', task.weblink)}
                                <button onClick={() => window.open(task.weblink, '_blank')} className="buttonBigGreen">
                                    {tr[tr.user_lang]['weblink']}
                                </button>
                            </div>
                        ) : null}

                        {task.type == 'form' && task.bybit_link == null ? (
                            <>
                                <div className='task_form'>
                                    <form onSubmit={handleSubmit}>
                                        <div className='task_form_row'>
                                            <input
                                                className="task_form_input"
                                                type="text"
                                                id="inputField"
                                                value={inputValue}
                                                placeholder="Enter your BID"
                                                onChange={(e) => setInputValue(e.target.value)}
                                            />

                                            <button
                                                type="submit"
                                                className="task_form_submit"
                                            >Passed</button>
                                        </div>
                                    </form>
                                </div>
                            </>
                        ) : task.type == 'form' && task.bybit_link !== null ? (
                            <div className='task_green_text'>The task is completed!</div>
                        ) : null}


                        <button className='task_bottom_panel_close' onClick={closePanel}>
                            <img src="/images/close.png" style={{ width: '24px', height: '24px' }} />
                        </button>
                    </div>
                </SlideBottomPanel>
            ) : null}
            <ToastContainer />
        </>
    );
})

export default Mine;

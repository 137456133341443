import React, { useEffect, useRef, useState } from 'react';
import { observer } from "mobx-react-lite";

//Конфиг
import config from '../config';

//Стили
import '../css/referal_links.css';

//Языки / переводы / сторы
import translationStore from '../locales/locales';
import PlayerStore from '../store/playerStore';

//Toast сообщения
import { ToastContainer, toast, Bounce, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ReferalLink = observer(() => {
    //Языки / переводы
    const tr = translationStore.trnsl;

    //Для формы
    const [link] = useState(config.telegramRefer + PlayerStore.player.refcode);
    const linkRef = useRef(null); //Ссылка на инпут

    const copyToClipboard = () => {
        if (linkRef.current) {
            linkRef.current.select();
            document.execCommand('copy');
            toast.success(tr[tr.user_lang].support_copy_link, {
                position: "bottom-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Slide,
            });
        }
    };

    return (
        <>
            <div className='referal_link_main'>
                <div className='referal_link_title'>
                    {tr[tr.user_lang].invite_link}
                </div>
                <div className='referal_link_form'>
                    <div className='referal_link_row' style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                            ref={linkRef}
                            value={link}
                            readOnly
                            className='referal_link_input'
                        />
                        <button
                            onClick={copyToClipboard}
                            className='referal_link_copybutton'
                        >
                            <img src='/images/icon-copy.svg' className='icon-copy' />
                        </button>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    );
});

export default ReferalLink;

import React, { useEffect, useRef, useState } from 'react';
import { observer } from "mobx-react-lite";
import { Routes, Route, Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

//Стили
import '../css/top-header.css';
import '../css/modal_styles.css';

//Языки / переводы / сторы
import translationStore from '../locales/locales';
import PlayerStore from '../store/playerStore';

//Функция для смены языка
const handleSwichLang = () => {
    if (translationStore.trnsl.user_lang == 'ru') {
        translationStore.setTranslations('user_lang', 'en');
    }
    else {
        translationStore.setTranslations('user_lang', 'ru');
    }
}

//Шаблон прогресс бара (шкала)
const ProgressBar = ({ progress }) => {
    return (
        <div className='top_header_user_rang_level_scale'>
            <div className='top_header_user_rang_level_scale_fill'>
                <div className='top_header_user_rang_level_scale_filling' style={{ width: `${progress}%` }}></div>
            </div>
        </div>
    );
};

const TopHeader = observer(({ player }) => {
    //Языки / переводы
    const trnsl = translationStore.trnsl;

    //Загружает наши уровни
    const levels = PlayerStore.player.levels;

    //Стейты нужные для шкалы прогрессбара 
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [progress, setProgress] = useState(0);
    const [levelInfo, setLevelInfo] = useState({ level: 0, rank: '' });


    useEffect(() => {
        const checkingLevelUser = async () => {
            try {
                setLoading(true);
                const balance = PlayerStore.player.balance;

                for (const level of levels) {
                    if (balance >= level.minBalance && balance < level.maxBalance) {
                        PlayerStore.editPlayerStore('level', level.level);
                        PlayerStore.editPlayerStore('rank', level.rank);
                        setLevelInfo({ level: level.level, rank: level.rank });
                        const levelProgress =
                            ((balance - level.minBalance) / (level.maxBalance - level.minBalance)) * 100;
                        setProgress(levelProgress);
                        break;
                    }
                }
            } catch (error) {
                setError('Ошибка проверки уровня пользователя! ' + error.message);
            } finally {
                setLoading(false);
            }
        };

        checkingLevelUser();
    }, [PlayerStore.player.balance]);


    //Модальное окно в шапке (комбинации)
    // Состояние для управления показом/скрытием модального окна
    const [showModal, setShowModal] = useState(false);

    // Функции для открытия и закрытия модального окна
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    return (
        <>
            <div className='top_header_wrap'>
                <div className='top_header_inner_wrap'>

                    <div className='top_header_user_avatar_wrap'>
                        <div className='top_header_user_avatar'>
                            {PlayerStore.player.user_photo ? (
                                <img src={PlayerStore.player.user_photo} />
                            ) : (
                                <img src='/images/ava_default.png' />
                            )}
                        </div>
                    </div>

                    <div className='top_header_user_rang'>
                        <div className='top_header_user_rang_level'>
                            <div className='top_header_user_rang_level_wrap'>
                                <div className='top_header_user_rang_level_number'>
                                    lvl {PlayerStore.player.level}
                                </div>
                                <div className='top_header_user_rang_level_name'>
                                    {translationStore.trnsl[translationStore.trnsl.user_lang][PlayerStore.player.rank]}
                                </div>
                            </div>
                        </div>
                        <ProgressBar progress={progress} />
                    </div>

                    <div className='top_header_user_lang'>
                        <div className='top_header_user_lang_wrap'>
                            <button className='lang_button' onClick={handleShow}>
                                <img style={{ width: 22, height: 22 }} src='/images/combination.svg' />
                            </button>

                        </div>
                    </div>

                </div>
            </div>

            <Modal
                show={showModal}
                onHide={handleClose} centered>
                <button className='buttonClose' onClick={handleClose}>
                    <img src='images/close.png' />
                </button>
                <div className='modalHeader'>
                    <div className='modalHeaderTitle'>VaBank combinations</div>
                </div>
                <div className='modalContent'>
                    <div className='combinations'>
                        <img src='images/Combo2.png' />
                    </div>
                </div>
            </Modal>
        </>
    );
});

export default TopHeader;

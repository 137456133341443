import { makeObservable, observable, action } from 'mobx';

class TranslationStore {

    trnsl = {
        user_lang: 'en',
        en: {
            home: 'Home',
            tasks: 'Tasks',
            mine: 'Invest',
            frends: 'Frends',
            at_you: 'At you',
            scrolling: 'scrolling',
            beggar: 'Beggar',
            janitor: 'Janitor',
            worker: 'Worker',
            engineer: 'Engineer',
            it_technician: 'It technician',
            cryptan: 'Cryptan',
            top_Manager: 'Top Manager',
            seo: 'SEO',
            major: 'Major',
            banker: 'Banker',
            president: 'President',
            you_balance: 'Your balance',
            do_it: 'Complete tasks and get extra spins and tokens!',
            subscribe: 'Subscribe',
            bybitref_link: 'Registration on ByBit',
            passed: 'Passed',
            check: 'Check',
            referals: 'Referals',
            bonus: 'Invite a friend and you and your friend will receive bonuses!',
            invite_link: 'My invite link',
            my_referals: 'My referals',
            line: 'line',
            invite: 'Invite',
            no_referals: 'You dont have referals yet',
            invite_text: 'Play with me and earn tokens.',
            invite_frends: 'Invite friends',
            coming_soon: 'Coming soon',
            support_a_friend: 'Gift for friend',
            support_introtext: 'Send a referral link and choose a gift for your friend. Boost your karma!',
            support_create: 'Create',
            support_gift: 'Gift',
            support_gifts: 'Gifts',
            support_copy_link: 'The link has been copied!',
            is_not_balance: 'There is not enough balance',
            support_link_already_exists: 'Error! The link has already been created or you dont have enough balance',
            weblink: 'Follow the link',
            wait: 'Wait',
            airdrop_wallet: "Your airdrop wallet",
            wallet_is_connected: "The wallet is connected",
            wallet_is_not_connected: "The wallet is not connected",
            user_usdt_balance: "USDT balance",
            usdt_add: "Deposit",
            exchange_ton: "Exchange TON for USDT",
            exchange_vbk: "Exchange VBK for USDT",
            will_receive: "You will receive",
            button_text_confirm: "Send a transaction",
            user_vabank_balance: "VaBank balance",
            usdt_exchange: "Exchange",
            withdraw: "Withdraw",
            withdraw_usdt: "Withdraw USDT to TON",
            transaction_info: "Withdrawals are available from $30, with a platform fee of 3% and a fixed TONE network fee of $1, which is deducted from the withdrawn tokens. Payouts are processed only on Wednesdays and Fridays during the day, so plan your transactions in advance and take advantage of the convenient withdrawal terms!",
            purchase_nft: "Purchase NFT",
            collect_reward_via: "You can collect the reward via",
            claim_reward: "Claim reward",
            nft_timer_info: "Complete the task to collect the reward",
            task_is_completed: "The task is completed!",
            reset_timer_button: "Reset the timer",
            timer_has_been_reset: "The timer has been reset! Now you can complete this task",
            user_vbk_balance: "VBK balance",
        },
        ru: {
            home: 'Главная',
            tasks: 'Задания',
            mine: 'Invest',
            frends: 'Друзья',
            at_you: 'У Вас',
            scrolling: 'прокрутов',
            beggar: 'Beggar',
            janitor: 'Janitor',
            worker: 'Worker',
            engineer: 'Engineer',
            it_technician: 'It technician',
            cryptan: 'Cryptan',
            top_Manager: 'Top Manager',
            seo: 'SEO',
            major: 'Major',
            banker: 'Banker',
            president: 'President',
            you_balance: 'Ваш баланс',
            do_it: 'Выполняй задания и получай дополнительные спины и токены!',
            subscribe: 'Подписаться',
            bybitref_link: 'Регистрация на ByBit',
            passed: 'Пройден',
            check: 'Проверить',
            referals: 'Рефералы',
            bonus: 'Пригласи друга и вы и ваш друг получите бонусы!',
            invite_link: 'Моя реф. ссылка',
            my_referals: 'Мои рефералы',
            line: 'линия',
            invite: 'Пригласить',
            no_referals: 'У Вас ещё нет рефералов',
            invite_text: 'Играй со мной, и зарабатывай токены.',
            invite_frends: 'Пригласи друзей',
            coming_soon: 'Скоро будет',
            support_a_friend: 'Поддержи друга',
            support_introtext: 'Отправь реферальную ссылку и выбери подарок своему другу. Прокачай свою карму!',
            support_create: 'Создать',
            support_gift: 'Подарок',
            support_gifts: 'Подарки',
            support_copy_link: 'Ссылка скопирована!',
            is_not_balance: 'Не хватает монет на балансе',
            support_link_already_exists: 'Ошибка! Ссылка уже создана или у Вас не хватает баланса',
            weblink: 'Перейти по ссылке',
            wait: 'Ждите',
            airdrop_wallet: "Твой кошелек для аирдропа",
            wallet_is_connected: "Кошелёк подключен!",
            wallet_is_not_connected: "Кошелёк не подключен!",
            user_usdt_balance: "Баланс USDT",
            usdt_add: "Пополнить",
            exchange_ton: "Обменяйте TON на USDT",
            exchange_vbk: "Обменяйте VBK на USDT",
            will_receive: "Вы получите",
            button_text_confirm: "Отправить транзакцию",
            user_vabank_balance: "Баланс VaBank",
            usdt_exchange: "Обменять",
            withdraw: "Вывести",
            withdraw_usdt: "Вывести USDT в TON",
            transaction_info: "Вывод средств возможен от 30 долларов США с комиссией платформы в размере 3% и фиксированной комиссией TONE network в размере 1 доллара США, которая вычитается из суммы выводимых токенов. Выплаты производятся только по средам и пятницам в течение дня, поэтому планируйте свои транзакции заранее и воспользуйтесь удобными условиями вывода средств!",
            purchase_nft: "Купить NFT",
            collect_reward_via: "Забрать вознаграждение можно через",
            claim_reward: "Забрать вознаграждение",
            nft_timer_info: "Выполните задание чтобы забрать награду",
            task_is_completed: "Задание выполнено!",
            reset_timer_button: "Сбросить таймер",
            timer_has_been_reset: "Таймер сброшен! Теперь Вы можете пройти это задание",
            user_vbk_balance: "VBK баланс",
        },
        es: {
            home: 'Inicio',
            tasks: 'tasks',
            mine: 'Invest',
            frends: 'Amigos',
            at_you: 'Tú',
            scrolling: 'desplazamiento',
            beggar: 'Beggar',
            janitor: 'Janitor',
            worker: 'Worker',
            engineer: 'Engineer',
            it_technician: 'It technician',
            cryptan: 'Cryptan',
            top_Manager: 'Top Manager',
            seo: 'SEO',
            major: 'Major',
            banker: 'Banker',
            president: 'President',
            you_balance: 'Tu saldo',
            do_it: '¡Completa tareas y consigue giros y fichas extra!',
            subscribe: 'Suscríbete',
            bybitref_link: 'Regístrate en ByBit',
            passed: 'Aprobado',
            check: 'Comprobar',
            referals: 'Referidos',
            bonus: 'Invita a un amigo y tú y tu amigo recibiréis bonus!',
            invite_link: 'Mi enlace de referidos',
            my_referals: 'Mis referidos',
            line: 'Línea',
            invite: 'Invitar',
            no_referals: 'Aún no tienes referidos',
            invite_text: 'Juega conmigo y gana tokens.',
            invite_frends: 'Invita a tus amigos',
            coming_soon: 'Próximamente',
            support_a_friend: 'Apoya a un amigo',
            support_introtext: 'Envía un enlace de recomendación y elige un regalo para tu amigo. Aumenta tu karma!',
            support_create: 'Crear',
            support_gift: 'Regalo',
            support_gifts: 'Regalos',
            support_copy_link: 'Enlace copiado!',
            is_not_balance: 'No hay suficientes monedas en el saldo',
            support_link_already_exists: 'Error! El enlace ya ha sido creado o no tienes suficiente saldo',
            weblink: 'Sigue el enlace',
            wait: 'Espera',
            airdrop_wallet: "Tu monedero airdrop",
            wallet_is_connected: "Cartera conectada",
            wallet_is_not_connected: "Cartera no conectada",
            user_usdt_balance: "Saldo usdt",
            usdt_add: "Recargar",
            exchange_ton: "Cambiar TON a USDT",
            exchange_vbk: "Cambiar VBK a USDT",
            will_receive: "Usted recibirá",
            button_text_confirm: "Enviar transacción",
            user_vabank_balance: "Balance de wabank",
            usdt_exchange: "Canjear",
            withdraw: "Hacer salir",
            withdraw_usdt: "Retirar USDT a TON",
            transaction_info: "Los retiros están disponibles desde $30, con una tarifa de plataforma del 3% y una tarifa fija de red de TONO de 1 1, que se deduce de los tokens retirados. Los pagos se procesan solo los miércoles y viernes durante el día, ¡así que planifique sus transacciones con anticipación y aproveche los convenientes términos de retiro!",
            purchase_nft: "Compra de NFT",
            collect_reward_via: "La recompensa se puede recoger a través de",
            claim_reward: "Reclamar recompensa",
            nft_timer_info: "Completa la tarea para recoger la recompensa",
            task_is_completed: "¡Misión cumplida!",
            reset_timer_button: "Restablecer temporizador",
            timer_has_been_reset: "¡El temporizador se ha restablecido! Ahora Puedes completar esta tarea",
            user_vbk_balance: "Equilibrio VBK",
        }
    };


    constructor() {
        makeObservable(this, {
            trnsl: observable,
            setTranslations: action
        });
    }

    setTranslations(lang, trnsl) {
        this.trnsl[lang] = trnsl;
    }
}

const translationStore = new TranslationStore();
export default translationStore;
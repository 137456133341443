import React, { useEffect, useRef, useState } from 'react';
import { observer } from "mobx-react-lite";

//Стили
import '../css/home.css';

//Компоненты
import Slots from '../components/Slots';
import TopHeader from '../components/TopHeader';
import Balance from '../components/Balance';
import Girl from '../components/Girl';
import Reklama from '../components/Reklama'; // Тест коммит 2 второй тест



//Сторы
import PlayerStore from '../store/playerStore';


const Home = observer(() => {

  //Появление слотмашины
  const [showSlotMachine, setShowSlotMachine] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSlotMachine(true); // Обновляем данные через 3 секунды
    }, 3000);

    return () => clearTimeout(timer); // Очищаем таймер при размонтировании компонента
  }, []);


  return (
    <>
      {/*<div style={{ color: '#ffffff' }}>{JSON.stringify(PlayerStore.player)}</div>*/}
      <div className='slot_machine_flex_top'>
        <TopHeader />
        <Balance />
      </div>

      <div className='slot_machine_flex_body'>
        <div className={`slot_machine_bg_main_wrap ${showSlotMachine ? 'slot_slide-in slot_slide-in-active' : 'slot_slide-in'}`}>
          <Reklama />
          <div className='slot_machine_combo'></div>
          <Girl />
          <div className='slot_machine_bg'>
            <div className='slot_machine_bg_wrap'>
              <Slots />
            </div>
          </div>

        </div>
      </div>
    </>
  );
});

export default Home;

import React from 'react';
import { observer } from "mobx-react-lite";
import { Routes, Route, Link } from 'react-router-dom';

//Стили
import '../css/templatereferal.css';

//Языки / переводы
import translationStore from '../locales/locales';


const TemplateReferal = observer(({ referal }) => {
    //Языки / переводы
    const trnsl = translationStore.trnsl;

    // Обработчик события onError для замены изображения на дефолтное
    const handleImageError = (event) => {
        event.target.src = '/images/ava_default.png';
    };

    return (
        <>
            <div className='referal_item'>
                <div className='referal_item_row'>
                    <div className='referal_item_image'>
                        <img
                            src={referal?.user_photo ? referal?.user_photo : '/images/ava_default.png'}
                            className='referal_user_avatar'
                            onError={handleImageError}
                        />
                    </div>
                    <div className='referal_item_name'>
                        <div>
                            <div className='referal_item_name_user'>
                                {referal?.username ? referal?.username : "no name"}
                            </div>
                        </div>
                    </div>
                    <div className='referal_item_rew'>
                        <div className='referal_item_rewmonets'>
                            {referal?.balance} <img src='/images/moneta.png' className='referal_item_icon_monets' />
                        </div>
                        <div className='referal_item_referals'>
                            {referal?.referal_count} <img src='/images/icon_user.png' className='referal_item_icon_users' />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
});

export default TemplateReferal;

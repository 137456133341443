import { makeAutoObservable } from "mobx";
import axios from 'axios';

//Сторы
import PlayerStore from '../store/playerStore';

//Конфиг
import config from '../config';

//Toast сообщения
import { ToastContainer, toast, Bounce, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


class NftStore {

    nft = {
        shop: [

        ],
        myNft: [

        ]
    }

    //Делаем отслеживаемость
    constructor() {
        makeAutoObservable(this)
    }

    //Добавляем
    editNftStore(attr, value) {
        this.nft[attr] = value;
    }

    //Метод для полной замены объекта Nft
    setNft(newNftData) {
        this.nft = { ...newNftData };
    }

    //Метод для обновления вложенных параметров
    updateNftParameter(path, value) {
        // Преобразуем путь с квадратными скобками в формат с точками
        const keys = path.replace(/\[(\d+)\]/g, '.$1').split('.');  // Преобразует 'shop[0].category' в 'shop.0.category'
        let obj = this.nft;

        // Проходим по объекту, чтобы найти необходимое вложенное свойство
        for (let i = 0; i < keys.length - 1; i++) {
            obj = obj[keys[i]];
        }

        // Обновляем значение последнего ключа
        obj[keys[keys.length - 1]] = value;
    }

    //Купить NFT
    buyNft = (data) => {
        console.log('idchat: ', PlayerStore.player.idchat, ' idnft: ', data.id);

        const apiUrl = config.apiBaseUrl + '/api/api/v1/buynft';
        const token = PlayerStore.player.token; // Получаем токен

        // Заголовки запроса
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Добавляем токен в заголовок Authorization
        };
        //Отправляемые даныне
        const dataRec = {
            idchat: PlayerStore.player.idchat,
            idnft: data.id
        };
        // Отправляем запрос
        axios.post(apiUrl, dataRec, { headers })
            .then(response => {
                console.log('Ответ tocollectrewardnft: ', response?.data);
                if (response?.data?.error) {
                    toast.error(response?.data?.message, {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                        transition: Slide,
                    });
                }
                if (response?.data?.error === false) {
                    this.myNftList(); //Послепокупке занова запрашиваем мои NFT
                    toast.success(response?.data?.message, {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                        transition: Slide,
                    });
                }

            })
            .catch(error => {
                if (error.response) {
                    // Сервер ответил статусом, который выходит за пределы диапазона 2xx
                    console.error('Ошибка сброса таймера toCollectRewardNft 2xx:', error.response.data);
                } else if (error.request) {
                    // Запрос был сделан, но ответ не был получен
                    console.error('Ошибка сброса таймера toCollectRewardNft запрос:', error.request);
                } else {
                    // Ошибка при настройке запроса
                    console.error('Ошибка сброса таймера toCollectRewardNft:', error.message);
                }
            });
    }

    //Функция запроса МОИХ NFT с сервера
    myNftList = () => {
        const apiUrl = config.apiBaseUrl + '/api/api/v1/mynft';
        const token = PlayerStore.player.token; // Получаем токен

        // Заголовки запроса
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Добавляем токен в заголовок Authorization
        };
        //Отправляемые даныне
        const dataRec = {

        };

        axios.get(apiUrl, { headers })
            .then(response => {
                console.log('СПИСОК МОИХ NFT: ', JSON.stringify(response.data, null, 1));
                if (response?.data) {
                    this.editNftStore('myNft', response?.data);
                }
            })
            .catch(error => {
                if (error.response) {
                    // Сервер ответил статусом, который выходит за пределы диапазона 2xx
                    console.error('Ошибка 2xx:', error.response.data);
                } else if (error.request) {
                    // Запрос был сделан, но ответ не был получен
                    console.error('Ошибка запроса:', error.request);
                } else {
                    // Ошибка при настройке запроса
                    console.error('Ошибка:', error.message);
                }
            });
    }


}

const nftStore = new NftStore();
export default nftStore; 
import React, { useEffect, useState, useRef } from 'react';
import styled, { keyframes, css } from 'styled-components';

// Контейнер для анимации
const AnimationContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  pointer-events: none;
`;

// Основной компонент анимации монет
const CoinAnimation = ({ targetRef, trigger }) => {
  const [coins, setCoins] = useState([]);
  const containerRef = useRef(null);

  useEffect(() => {
    if (targetRef.current && containerRef.current && trigger === true) {
      const targetRect = targetRef.current.getBoundingClientRect();
      const containerRect = containerRef.current.getBoundingClientRect();
      const targetX = targetRect.left + targetRect.width / 2;
      const targetY = targetRect.top + targetRect.height / 2;

      const newCoins = Array.from({ length: 50 }, (_, index) => {
        const startX = Math.random() * containerRect.width;
        const startY = containerRect.height;

        const flyAnimation = keyframes`
          0% {
            transform: translate(${startX}px, ${startY}px) scale(0.8);
            opacity: 1;
          }
          80% {
            transform: translate(${targetX - containerRect.left}px, ${targetY - containerRect.top}px) scale(1.2);
            opacity: 1;
          }
          100% {
            transform: translate(${targetX - containerRect.left}px, ${targetY - containerRect.top}px) scale(3.5);
            opacity: 0;
          }
        `;

        const Coin = styled.img`
          position: absolute;
          width: 30px;
          height: 30px;
          animation: ${css`${flyAnimation}`} 2s ease-in-out forwards;
          animation-delay: ${Math.random() * 2}s;
          opacity:0
        `;

        return {
          id: index,
          element: (
            <Coin
              key={index}
              src="/images/moneta.png" // URL изображения монеты
            />
          ),
        };
      });

      setCoins(newCoins);
    }
  }, [targetRef, trigger]);

  return (
    <AnimationContainer ref={containerRef}>
      {coins.map((coin) => coin.element)}
    </AnimationContainer>
  );
};

export default CoinAnimation;

import React, { useEffect, useRef, useState } from 'react';
import { observer } from "mobx-react-lite";
import axios from 'axios';
import { Routes, Route, Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

//Стили
import '../css/modal.css';

//Языки / переводы
import translationStore from '../locales/locales';

//Компоненты
import NftTimer from '../components/NftTimer';

//Сторы
import PlayerStore from '../store/playerStore';
import nftStore from '../store/nftStore';

//Конфиг
import config from '../config';

//Toast сообщения
import { ToastContainer, toast, Bounce, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const NftModals = observer(({ open, onOpenChange, data }) => {

    //Языки / переводы
    const tr = translationStore.trnsl;

    const handleCloseNft = () => {
        onOpenChange(false); //Запускаем калбек фенкцию с параметром false чтобы зактыть модальное окно
    }


    //Подароки пользователям
    //const giftUsers = () => {
    //    const apiUrl = config.apiBaseUrl + '/api/api/v1/addgift';
    //    const token = playerStore.player.token; // Получаем токен
    //
    //    // Заголовки запроса
    //    const headers = {
    //        'Accept': 'application/json',
    //        'Content-Type': 'application/json',
    //        'Authorization': `Bearer ${token}` // Добавляем токен в заголовок Authorization
    //    };
    //    //Отправляемые даныне
    //    const dataRec = {
    //        'idchat': playerStore.player.idchat,
    //        'amount': currentGift
    //    };
    //
    //    axios.post(apiUrl, dataRec, { headers })
    //        .then(response => {
    //
    //            console.log('ПОДАРОК: ', JSON.stringify(response.data, null, 1));
    //            if (currentGift === 0) {
    //                setGiftArrayOne({
    //                    'gift_user': response.data.gift_user,
    //                    'nomerGift': currentGift
    //                });
    //            }
    //            if (currentGift === 1) {
    //                setGiftArrayTwo({
    //                    'gift_user': response.data.gift_user,
    //                    'nomerGift': currentGift
    //                });
    //                console.log('response.data.gift_user::', response.data.gift_user, 'currentGift::', currentGift);
    //            }
    //            if (currentGift === 2) {
    //                setGiftArrayThree({
    //                    'gift_user': response.data.gift_user,
    //                    'nomerGift': currentGift
    //                });
    //            }
    //
    //            setTimeout(() => {
    //                //Обновляем стор playerStore 
    //                effectStore.toggleEffectTrigger();//Тригер для срабатывания useEffect в App.js из дочерних компоннетов
    //            }, 1000);
    //
    //
    //        })
    //        .catch(error => {
    //            if (error.response) {
    //                // Сервер ответил статусом, который выходит за пределы диапазона 2xx
    //                console.error('Ошибка подарка:', error.response.data);
    //                toast.success(tr[tr.user_lang].support_link_already_exists, {
    //                    position: "bottom-center",
    //                    autoClose: 2000,
    //                    hideProgressBar: false,
    //                    closeOnClick: true,
    //                    pauseOnHover: true,
    //                    draggable: true,
    //                    progress: undefined,
    //                    theme: "dark",
    //                    transition: Slide,
    //                });
    //                if (playerStore?.player?.gifts?.some(gift => gift.amount == gifts[0])) {
    //                    setGiftArrayOne({
    //                        'gift_user': playerStore?.player?.gifts[0],
    //                        'nomerGift': currentGift
    //                    });
    //                }
    //                if (playerStore?.player?.gifts?.some(gift => gift.amount == gifts[1])) {
    //                    setGiftArrayTwo({
    //                        'gift_user': playerStore?.player?.gifts[1],
    //                        'nomerGift': currentGift
    //                    });
    //                }
    //                if (playerStore?.player?.gifts?.some(gift => gift.amount == gifts[2])) {
    //                    setGiftArrayThree({
    //                        'gift_user': playerStore?.player?.gifts[2],
    //                        'nomerGift': currentGift
    //                    });
    //                }
    //            } else if (error.request) {
    //                // Запрос был сделан, но ответ не был получен
    //                console.error('Ошибка запроса подарка:', error.request);
    //            } else {
    //                // Ошибка при настройке запроса
    //                console.error('Ошибка:', error.message);
    //            }
    //        });
    //}

    //Функция забрать награду 3-х часового квеста NFT
    const toCollectRewardNft = () => {

        const apiUrl = config.apiBaseUrl + '/api/api/v1/tocollectrewardnft';
        const token = PlayerStore.player.token; // Получаем токен

        // Заголовки запроса
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Добавляем токен в заголовок Authorization
        };

        // Отправляем запрос
        axios.post(apiUrl, {}, { headers })
            .then(response => {
                console.log('Ответ tocollectrewardnft: ', response?.data);
                if (response?.data?.error) {
                    toast.error(response?.data?.message, {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                        transition: Slide,
                    });
                }

                if (response?.data?.error === false) {
                    toast.success(response?.data?.message, {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                        transition: Slide,
                    });
                }
            })
            .catch(error => {
                if (error.response) {
                    // Сервер ответил статусом, который выходит за пределы диапазона 2xx
                    console.error('Ошибка сброса таймера toCollectRewardNft 2xx:', error.response.data);
                } else if (error.request) {
                    // Запрос был сделан, но ответ не был получен
                    console.error('Ошибка сброса таймера toCollectRewardNft запрос:', error.request);
                } else {
                    // Ошибка при настройке запроса
                    console.error('Ошибка сброса таймера toCollectRewardNft:', error.message);
                }
            });
    };


    return (
        <div className='modal_wrap'>
            <Modal
                show={open}
                onHide={handleCloseNft} centered>
                <div className='modalContent'>
                    <button className='buttonClose' onClick={handleCloseNft}>
                        <img src='images/close.png' />
                    </button>

                    <div className='modal_info'>
                        {data?.nft_data ? (
                            <div className='modalImageWrap'>
                                <img className='modalImage' src={`/nft/nft${data.nft_data.id}.png`} />
                            </div>
                        ) : (
                            <div className='modalImageWrap'>
                                <img className='modalImage' src={`/nft/nft${data.id}.png`} />
                            </div>
                        )}

                        <div className='modalTextContents'>
                            {data?.nft_data ? (
                                <>
                                    <div className='modalTextTitle'>{data.nft_data[`text${tr.user_lang}`]}</div>
                                    <div className='modalTextDes'>{data.nft_data[`des${tr.user_lang}`]}</div>
                                </>
                            ) : (
                                <>
                                    <div className='modalTextTitle'>{data[`text${tr.user_lang}`]}</div>
                                    <div className='modalTextDes'>{data[`des${tr.user_lang}`]}</div>
                                </>
                            )}
                            {data?.nft_timer ? (
                                <>
                                    <div className='nftTimerWrapModal'>
                                        <div className='nftTimerModal'>
                                            <div className='nftRewardVia'>{tr[tr.user_lang].collect_reward_via}</div>
                                            <NftTimer targetDate={data?.nft_timer} />
                                        </div>
                                    </div>
                                    <div className='nftTimerInfo'>
                                        <div className='nftTimerInfoText'>{tr[tr.user_lang].nft_timer_info}</div>
                                        <Link to="/tasks" className='nftTimerTiskLink'><span>{tr[tr.user_lang].tasks}</span></Link>
                                    </div>
                                </>
                            ) : null}
                            <div className='modalTextAtributs'>
                                <div className='modalTextAtributsPrice'>{data.price} USDT</div>
                                <div className='modalTextAtributsProcents'>{data.procent}%</div>
                            </div>
                            {data?.nft_timer ? (
                                <div className='modalTextButton'>
                                    <button
                                        className='modal_butto_blue'
                                        onClick={() =>
                                            toCollectRewardNft()
                                        }>
                                        {tr[tr.user_lang].claim_reward}
                                    </button>
                                </div>
                            ) : (
                                <div className='modalTextButton'>
                                    <button className='modal_butto_blue' onClick={() => nftStore.buyNft(data)}>
                                        {tr[tr.user_lang].purchase_nft}
                                    </button>
                                </div>
                            )}

                        </div>

                    </div>
                </div>
            </Modal>

        </div>
    );
});

export default NftModals;

import React, { useEffect, useRef, useState } from 'react';
import { observer } from "mobx-react-lite";
import axios from 'axios';
import { Routes, Route, Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

import effectStore from '../store/effectStore'; // Триггер для срабатывания useEffect в App.js из дочерних компонентов

//Стили
import '../css/giftfriend.css';

//Языки / переводы
import translationStore from '../locales/locales';
import playerStore from '../store/playerStore';

//Конфиг
import config from '../config';

//Toast сообщения
import { ToastContainer, toast, Bounce, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Giftfriend = observer(({ open, onOpenChange }) => {

    const gifts = ['10000', '50000', '100000']; //Список подарков

    //Языки / переводы
    const tr = translationStore.trnsl;

    const handleCloseGift = () => {
        onOpenChange(false); //Запускаем калбек фенкцию с параметром false чтобы зактыть модальное окно
    }

    //Копирвоание ссылки
    const copyToClipboard = (ref) => {
        if (ref.current) {
            ref.current.select();
            document.execCommand('copy');
            toast.success(tr[tr.user_lang].support_copy_link, {
                position: "bottom-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Slide,
            });
        }
    };

    //Выбор подарка
    const [giftOne, setGiftOne] = useState(false); // Выбор 1 подарка
    const [giftTwo, setGiftTwo] = useState(false); // Выбор 2 подарка
    const [giftThree, setGiftThree] = useState(false); // Выбор 3 подарка

    const [currentGift, setCurrentGift] = useState(null); // Текущий подарок
    const [isDisabled, setIsDisabled] = useState(true); //Активная или не активная кнопка создания подарка

    const [giftArrayOne, setGiftArrayOne] = useState(null); //Созданная ссылка 1
    const [giftArrayTwo, setGiftArrayTwo] = useState(null); //Созданная ссылка 2
    const [giftArrayThree, setGiftArrayThree] = useState(null); //Созданная ссылка 3

    const giftOneRef = useRef(null); // REF Ссылка подарка 1
    const giftTwoRef = useRef(null); // REF Ссылка подарка 2
    const giftThreeRef = useRef(null); // REF Ссылка подарка 3

    const handleGiftCheck = (value) => {
        // Создаем объект, где ключи - это значения, а значения - состояния
        const giftsState = {
            0: [true, false, false],
            1: [false, true, false],
            2: [false, false, true],
        };

        // Извлекаем состояния из объекта или устанавливаем все false по умолчанию
        const [giftOneState, giftTwoState, giftThreeState] = giftsState[value] || [false, false, false];

        setGiftOne(giftOneState);
        setGiftTwo(giftTwoState);
        setGiftThree(giftThreeState);
        setCurrentGift(value);
        setTimeout(() => {
            setIsDisabled(false);
        }, 500); //Обновить будет возможно раз в 3 секунды
    };


    //Подароки пользователям
    const giftUsers = () => {
        const apiUrl = config.apiBaseUrl + '/api/api/v1/addgift';
        const token = playerStore.player.token; // Получаем токен

        // Заголовки запроса
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Добавляем токен в заголовок Authorization
        };
        //Отправляемые даныне
        const dataRec = {
            'idchat': playerStore.player.idchat,
            'amount': currentGift
        };

        axios.post(apiUrl, dataRec, { headers })
            .then(response => {

                console.log('ПОДАРОК: ', JSON.stringify(response.data, null, 1));
                if (currentGift === 0) {
                    setGiftArrayOne({
                        'gift_user': response.data.gift_user,
                        'nomerGift': currentGift
                    });
                }
                if (currentGift === 1) {
                    setGiftArrayTwo({
                        'gift_user': response.data.gift_user,
                        'nomerGift': currentGift
                    });
                    console.log('response.data.gift_user::', response.data.gift_user, 'currentGift::', currentGift);
                }
                if (currentGift === 2) {
                    setGiftArrayThree({
                        'gift_user': response.data.gift_user,
                        'nomerGift': currentGift
                    });
                }

                setTimeout(() => {
                    //Обновляем стор playerStore 
                    effectStore.toggleEffectTrigger();//Тригер для срабатывания useEffect в App.js из дочерних компоннетов
                }, 1000);


            })
            .catch(error => {
                if (error.response) {
                    // Сервер ответил статусом, который выходит за пределы диапазона 2xx
                    console.error('Ошибка подарка:', error.response.data);
                    toast.success(tr[tr.user_lang].support_link_already_exists, {
                        position: "bottom-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                        transition: Slide,
                    });
                    if (playerStore?.player?.gifts?.some(gift => gift.amount == gifts[0])) {
                        setGiftArrayOne({
                            'gift_user': playerStore?.player?.gifts[0],
                            'nomerGift': currentGift
                        });
                    }
                    if (playerStore?.player?.gifts?.some(gift => gift.amount == gifts[1])) {
                        setGiftArrayTwo({
                            'gift_user': playerStore?.player?.gifts[1],
                            'nomerGift': currentGift
                        });
                    }
                    if (playerStore?.player?.gifts?.some(gift => gift.amount == gifts[2])) {
                        setGiftArrayThree({
                            'gift_user': playerStore?.player?.gifts[2],
                            'nomerGift': currentGift
                        });
                    }
                } else if (error.request) {
                    // Запрос был сделан, но ответ не был получен
                    console.error('Ошибка запроса подарка:', error.request);
                } else {
                    // Ошибка при настройке запроса
                    console.error('Ошибка:', error.message);
                }
            });
    }

    ////Приглашения для пользователей
    //const handleInviteGift = () => {
    //    const userId = window.Telegram.WebApp.initDataUnsafe.user.id;

    //    // Сообщение и ссылка
    //    const inviteMessage = tr[tr.user_lang].invite_text;
    //    const inviteLink = `https://t.me/${config.botName}?start=${playerStore.player.refcode}`;

    //    // Полная ссылка для пересылки
    //    //const fullInviteLink = `https://t.me/share/url?url=${encodeURIComponent(inviteLink)}&text=${encodeURIComponent(inviteMessage)}`;
    //    const fullInviteLink = `https://t.me/share/url?url=${encodeURIComponent(inviteLink)}`;

    //    // Открытие ссылки на пересылку сообщения с приглашением
    //    window.Telegram.WebApp.openLink(fullInviteLink);
    //};


    return (
        <div className='gift_wrap'>
            <Modal
                show={open}
                onHide={handleCloseGift} centered>
                <div className='modalContentGift'>
                    <button className='buttonClose' onClick={handleCloseGift}>
                        <img src='images/close.png' />
                    </button>

                    <div className='gift_list'>
                        <img className='gift' src='images/slide3.jpg' />
                    </div>

                    <div className='gift_info'>
                        <div className='gift_info_title'>
                            {tr[tr.user_lang].support_a_friend}
                        </div>
                        <div className='gift_info_introtext'>
                            {tr[tr.user_lang].support_introtext}
                        </div>
                        <div className='gift_list_buttons'>
                            <button
                                className={giftOne ? 'buttonGift_active' : 'buttonGift'}
                                onClick={() => handleGiftCheck(0)}>
                                {gifts[0]} <img src='/images/moneta.png' className='gift_icon_monets' />
                            </button>
                            <button
                                className={giftTwo ? 'buttonGift_active' : 'buttonGift'}
                                onClick={() => handleGiftCheck(1)}>
                                {gifts[1]} <img src='/images/moneta.png' className='gift_icon_monets' />
                            </button>
                            <button
                                className={giftThree ? 'buttonGift_active' : 'buttonGift'}
                                onClick={() => handleGiftCheck(2)}>
                                {gifts[2]} <img src='/images/moneta.png' className='gift_icon_monets' />
                            </button>
                        </div>
                        <div>
                            <button
                                className={isDisabled ? 'gift_button_send_noactive' : 'gift_button_send'}
                                onClick={() => giftUsers()}
                                disabled={isDisabled}>
                                {tr[tr.user_lang].support_create}
                            </button>
                        </div>

                        <div className='giftslist'>
                            {giftArrayOne !== null ? (
                                <div className='giftItem'>
                                    <div className='giftItemTitle'>{tr[tr.user_lang].support_gift} {giftArrayOne.gift_user.amount} <img src='/images/moneta.png' className='gift_icon_monets' /></div>
                                    <div className='referal_link_row'>
                                        <input
                                            ref={giftOneRef}
                                            value={config.telegramRefer + playerStore?.player?.refcode + '-' + giftArrayOne.gift_user.gift_kod}
                                            readOnly
                                            className='referal_link_input'
                                        />
                                        <button
                                            onClick={() => copyToClipboard(giftOneRef)}
                                            className='referal_link_copybutton'
                                        >
                                            <img src='/images/icon-copy.svg' className='icon-copy' />
                                        </button>
                                    </div>
                                </div>
                            ) : null}

                            {giftArrayTwo !== null ? (
                                <div className='giftItem'>
                                    <div className='giftItemTitle'>{tr[tr.user_lang].support_gift} {giftArrayTwo.gift_user.amount} <img src='/images/moneta.png' className='gift_icon_monets' /></div>
                                    <div className='referal_link_row'>
                                        <input
                                            ref={giftTwoRef}
                                            value={config.telegramRefer + playerStore.player.refcode + '-' + giftArrayTwo.gift_user.gift_kod}
                                            readOnly
                                            className='referal_link_input'
                                        />
                                        <button
                                            onClick={() => copyToClipboard(giftTwoRef)}
                                            className='referal_link_copybutton'
                                        >
                                            <img src='/images/icon-copy.svg' className='icon-copy' />
                                        </button>
                                    </div>
                                </div>
                            ) : null}

                            {giftArrayThree !== null ? (
                                <div className='giftItem'>
                                    <div className='giftItemTitle'>{tr[tr.user_lang].support_gift} {giftArrayThree.gift_user.amount} <img src='/images/moneta.png' className='gift_icon_monets' /></div>
                                    <div className='referal_link_row'>
                                        <input
                                            ref={giftThreeRef}
                                            value={config.telegramRefer + playerStore.player.refcode + '-' + giftArrayThree.gift_user.gift_kod}
                                            readOnly
                                            className='referal_link_input'
                                        />
                                        <button
                                            onClick={() => copyToClipboard(giftThreeRef)}
                                            className='referal_link_copybutton'
                                        >
                                            <img src='/images/icon-copy.svg' className='icon-copy' />
                                        </button>
                                    </div>
                                </div>
                            ) : null}

                        </div>
                    </div>
                </div>
            </Modal>

        </div>
    );
});

export default Giftfriend;

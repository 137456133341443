import React from 'react';

// Стили
import '../css/preloader.css';

const Preloader = () => {
    return (
        <div className="preloader">
            <img className='preloaderimage' src='/images/loading.jpg' />
        </div>
    );
};

export default Preloader;
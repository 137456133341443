import React, { useEffect, useRef, useState } from 'react';
import { observer } from "mobx-react-lite";
import { Routes, Route, Link } from 'react-router-dom';

//Стили
import '../css/winmonets.css';

//Языки / переводы
import translationStore from '../locales/locales';
import playerStore from '../store/playerStore';


const WinMonets = observer(({ winning_monets }) => {
    //Языки / переводы
    const trnsl = translationStore.trnsl;

    const [winning, setWinning] = useState('GOOD LUCK'); //Выигрыш
    useEffect(() => {
        if (winning_monets !== 0) {
            setWinning('YOUR WIN ' + winning_monets);
        }
        else {
            setWinning('GOOD LUCK');
        }

    }, [winning_monets]);

    return (
        <>
            <div className='winmonets_wrap'>
                {winning}
            </div>
        </>
    );
});

export default WinMonets;

import React, { useState, useEffect } from 'react';

const NftTimer = ({ targetDate }) => {
    const [timeLeft, setTimeLeft] = useState({});
    const [isExpired, setIsExpired] = useState(false);

    useEffect(() => {
        const getUserTimezoneOffset = () => {
            const offset = new Date().getTimezoneOffset(); // Смещение в минутах
            return -offset / 60; // Преобразуем в часы
        };

        // Преобразуем `targetDate` с учетом часового пояса пользователя
        const target = new Date(targetDate.replace(/-/g, '/'));
        const targetWithOffset = new Date(target.getTime() + getUserTimezoneOffset() * 60 * 60 * 1000);

        const intervalId = setInterval(() => {
            const now = new Date();
            const difference = targetWithOffset - now;

            if (difference <= 0) {
                setTimeLeft({ hours: 0, minutes: 0, seconds: 0 });
                setIsExpired(true);
            } else {
                const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
                const minutes = Math.floor((difference / (1000 * 60)) % 60);
                const seconds = Math.floor((difference / 1000) % 60);

                setTimeLeft({ hours, minutes, seconds });
                setIsExpired(false);
            }
        }, 1000);

        return () => clearInterval(intervalId);
    }, [targetDate]);

    return (
        <div>
            {isExpired ? (
                <div style={{ color: timeLeft.seconds % 2 === 0 ? 'red' : 'black' }}>
                    00:00:00
                </div>
            ) : (
                <div>
                    {String(timeLeft.hours || 0).padStart(2, '0')}:
                    {String(timeLeft.minutes || 0).padStart(2, '0')}:
                    {String(timeLeft.seconds || 0).padStart(2, '0')}
                </div>
            )}
        </div>
    );
};

export default NftTimer;

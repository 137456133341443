import { makeAutoObservable } from "mobx";

class EffectStore {
    shouldTriggerEffect = false;

    constructor() {
        makeAutoObservable(this);
    }

    toggleEffectTrigger() {
        this.shouldTriggerEffect = !this.shouldTriggerEffect;
    }
}

const effectStore = new EffectStore();
export default effectStore;

import React, { useEffect, useRef, useState } from 'react';
import { observer } from "mobx-react-lite";
import { Routes, Route, Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

//Стили
import '../css/start_banners.css';

//Языки / переводы
import translationStore from '../locales/locales';
import playerStore from '../store/playerStore';


const StartBanners = observer(() => {
    //Языки / переводы
    const trnsl = translationStore.trnsl;

    useEffect(() => {
        setTimeout(() => {
            setShowModalOne(true) //Начинаем с первого баннера через 5 секунд после загрузки приложения
        }, 5000);
    }, []);

    //Стейты для банныеров
    const [showModalOne, setShowModalOne] = useState(false);
    const [showModalTwo, setShowModalTwo] = useState(false);
    const [showModalThree, setShowModalThree] = useState(false);

    const handleClose = () => setShowModalOne(false);
    const handleCloseTwo = () => setShowModalTwo(false);
    const handleCloseThree = () => setShowModalThree(false);

    const handleOpenTwoModal = () => {
        setShowModalOne(false)
        setTimeout(() => {
            setShowModalTwo(true)
        }, 1000);

    }

    const handleOpenThreeModal = () => {
        setShowModalTwo(false)
        setTimeout(() => {
            setShowModalThree(true)
        }, 1000);
    }

    return (
        <div className='start_banners'>
            <Modal
                show={showModalOne}
                onHide={handleClose} centered>
                <div className='modalContentBanners'>
                    <button className='buttonClose' onClick={handleClose}>
                        <img src='images/close.png' />
                    </button>

                    <div className='banner_list'>
                        <img className='banner' src='images/slide1.jpg' />
                    </div>

                    <div className='banner_info'>
                        <div className='banner_info_title'>
                            Играй VaBank!
                        </div>
                        <div className='banner_info_introtext'>
                            Испытай удачу с поддержкой биржи Bybit и получай эксклюзивные бонусы! Присоединяйся и увеличивай свой капитал, выполняя выгодные задачи на бирже Bybit!
                        </div>
                        <div className='banner_info_button'>
                            <button className='buttonNewxSlide' onClick={handleOpenTwoModal}>
                                Идем дальше
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal
                show={showModalTwo}
                onHide={handleCloseTwo} centered>
                <div className='modalContentBanners'>
                    <button className='buttonClose' onClick={handleCloseTwo}>
                        <img src='images/close.png' />
                    </button>

                    <div className='banner_list'>
                        <img className='banner' src='images/slide2.jpg' />
                    </div>

                    <div className='banner_info'>
                        <div className='banner_info_title'>
                            Приглашай друзей и зарабатывай больше!
                        </div>
                        <div className='banner_info_introtext'>
                            1 и 2 линии: 50К монет за каждого приглашённого + 1 spin
                        </div>
                        <div className='banner_info_button'>
                            <button className='buttonNewxSlide' onClick={handleOpenThreeModal}>
                                Идем дальше
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal
                show={showModalThree}
                onHide={handleCloseThree} centered>
                <div className='modalContentBanners'>
                    <button className='buttonClose' onClick={handleCloseThree}>
                        <img src='images/close.png' />
                    </button>

                    <div className='banner_list'>
                        <img className='banner' src='images/slide3.jpg' />
                    </div>

                    <div className='banner_info'>
                        <div className='banner_info_title'>
                            Поддержи друга!
                        </div>
                        <div className='banner_info_introtext'>
                            Отправь реферальную ссылку и выбери подарок своему другу:<br />
                            10К монет / 50К монет / 100К монет<br />
                            Присоединяйся сейчас и пользуйся уникальными возможностями, прокачай свою карму
                        </div>
                        <div className='banner_info_button'>
                            <button className='buttonNewxSlide' onClick={handleCloseThree}>
                                Спасибо, понятно!
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>

        </div>
            );
});

export default StartBanners;

import React, { useEffect, useRef, useState } from 'react';
import { observer } from "mobx-react-lite";
import { Routes, Route, Link } from 'react-router-dom';

//Стили
import '../css/girl.css';

//Языки / переводы
import translationStore from '../locales/locales';
import playerStore from '../store/playerStore';


const Girl = observer(() => {
    //Языки / переводы
    const trnsl = translationStore.trnsl;

    const [showGirl, setShowGirl] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowGirl(true); // Обновляем данные через 3 секунды
        }, 3000);

        return () => clearTimeout(timer); // Очищаем таймер при размонтировании компонента
    }, []);

    return (
        <div className={`girl_wrap ${showGirl ? 'slide-in slide-in-active' : 'slide-in'}`}>
            {showGirl && (
                <img className='girl_img' src={'/images/girl' + playerStore.player.level + '.png'} alt="Girl" />
            )}
        </div>
    );
});

export default Girl;

import React, { useEffect, useState, useRef } from 'react';
import { observer } from "mobx-react-lite";
import axios from 'axios';

//Конфиг
import config from '../config';

// Сторы
import PlayerStore from '../store/playerStore';

// Стили
import '../css/down-message.css';

// Языки / переводы
import translationStore from '../locales/locales';

const DownMessage = observer(({ countSlots, drumcondition }) => {
    // Языки / переводы
    const trnsl = translationStore.trnsl;

    const [count, setCount] = useState(countSlots);
    const [timeLeft, setTimeLeft] = useState('00:00:00');
    const [timezone, setTimezone] = useState('');
    const [utcOffset, setUtcOffset] = useState(0);
    const [postAddSpins, setPostAddSpins] = useState(false);//запрос на добавление спинов

    const timerRef = useRef(null);

    // Тестовая функция, которая срабатывает по окончанию таймера
    const onTimerEnd = () => {
        console.log('Таймер завершен!');
        setPostAddSpins(true);
    };

    useEffect(() => {
        setCount(countSlots); // Обновляем данные пользователя
    }, [countSlots]);

    // Функция таймера
    const Timer = (time_scrolling) => {
        const scrollingTime = new Date(time_scrolling);
        let hasEnded = false; // Флаг для отслеживания, была ли функция вызвана
        let timeoutId = null; // Хранит идентификатор таймера
        let lastCountSlots = countSlots; // Хранит последнее значение countSlots
        let zeroStartTime = null; // Хранит время, когда countSlots стал 0

        if (timerRef.current) {
            clearInterval(timerRef.current);
        }

        // Если countSlots изменился
        if (countSlots !== lastCountSlots) {
            lastCountSlots = countSlots;
            if (countSlots === 0) {
                zeroStartTime = new Date(time_scrolling); // Запоминаем время, когда countSlots стал 0
            } else {
                zeroStartTime = null; // Сбрасываем время, если countSlots изменился
            }
        }

        // Обновляем таймер каждую секунду
        timerRef.current = setInterval(() => {
            const currentTime = new Date();
            const utcCurrentTime = new Date(currentTime.getTime() + (currentTime.getTimezoneOffset() * 60000));
            const difference = scrollingTime - utcCurrentTime;
            setTimeLeft('00:00:00');
            if (zeroStartTime !== null && (Date.now() - zeroStartTime >= 5000)) {
                if (!hasEnded) {
                    clearInterval(timerRef.current);
                    timerRef.current = null;
                    hasEnded = true; // Устанавливаем флаг, что функция уже была вызвана

                    // Устанавливаем таймер на 5 секунд, чтобы обновить отображаемое время
                    timeoutId = setTimeout(() => {
                        onTimerEnd(); // Вызов функции по окончанию таймера
                    }, 5000);
                }
            } else {
                setTimeLeft('00:00:00');
                const hours = String(Math.floor((difference / (1000 * 60 * 60)) % 24)).padStart(2, '0');
                const minutes = String(Math.floor((difference / (1000 * 60)) % 60)).padStart(2, '0');
                const seconds = String(Math.floor((difference / 1000) % 60)).padStart(2, '0');
                if (hours == 0 && minutes == 0 && seconds == 0) {
                    timeoutId = setTimeout(() => {
                        onTimerEnd(); // Вызов функции по окончанию таймера
                        clearInterval(timerRef.current);
                    }, 5000);
                }
                else if (hours <= 0 && minutes <= 0 && seconds <= 0) {
                    setTimeLeft('00:00:00');
                }
                else {
                    setTimeLeft(`${hours}:${minutes}:${seconds}`);
                }

            }
        }, 1000);
    }

    useEffect(() => {
        if (countSlots === 0) {
            setTimeout(() => {
                console.log('PlayerStore.player.time_scrolling :::', PlayerStore.player.time_scrolling);
                const scrollingTime = new Date(PlayerStore.player.time_scrolling);
                Timer(scrollingTime);
            }, 1000);
        }
        else {
            console.log('countSlots не НОЛЬ!');
        }

        return () => {
            if (timerRef.current) {
                clearInterval(timerRef.current);
            }
        };
    }, [countSlots, PlayerStore.player.time_scrolling]);

    // Часовая зона
    const getTimezone = () => {
        // Получаем информацию о текущей дате и времени
        const date = new Date();

        // Получаем строку с информацией о времени и часовом поясе
        const timezoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;

        // Получаем смещение в минутах и преобразуем его в часы
        const offsetMinutes = date.getTimezoneOffset();
        const offsetHours = -offsetMinutes / 60; // Отрицательное значение, потому что getTimezoneOffset возвращает отрицательное значение для положительных смещений

        // Устанавливаем значения в состояние
        setTimezone(timezoneString);
        setUtcOffset(offsetHours);
        console.log('ЧАСОВАЯ ЗОНА', offsetHours);
        PlayerStore.editPlayerStore('timezone', offsetHours);
    };
    useEffect(() => {
        getTimezone();
    }, []);

    // Добавляем спин по окончанию времени
    useEffect(() => {
        if (postAddSpins === true) {
            setPostAddSpins(false);
            const apiUrl = config.apiBaseUrl + '/api/api/v1/addspins';

            const token = PlayerStore.player.token; // Получаем токен

            // Заголовки запроса
            const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}` // Добавляем токен в заголовок Authorization
            };

            const dataRec = {
                "idchat": String(PlayerStore.player.idchat),
            };

            axios.post(apiUrl, dataRec, { headers })
                .then(response => {
                    console.log("ПОЛЬЗОВАТЕЛЬ ОБНОВЛЁН СПИНЫ ДОБАВЛЕНЫ: ", JSON.stringify(response.data, null, 1));
                    //PlayerStore.setPlayer(response.data.user);
                    // Обновляем PlayerStore

                    PlayerStore.editPlayerStore('scrolling', response.data.scrolling);
                    console.log('СПИНЫ ДОБАВЛЕНЫ! ', response.data);
                })
                .catch(error => {
                    if (error.response) {
                        // Сервер ответил статусом, который выходит за пределы диапазона 2xx
                        console.error('Ошибка:', error.response.data);
                    } else if (error.request) {
                        // Запрос был сделан, но ответ не был получен
                        console.error('Ошибка запроса:', error.request);
                    } else {
                        // Ошибка при настройке запроса
                        console.error('Ошибка:', error.message);
                    }
                });
        };
    }, [postAddSpins]);

    return (
        <>
            <div className='down_message_wrap'>
                {drumcondition === false ? (
                    <div className='down_message_inner_wrap'>
                        <div className='down_message_inner_wrap'>
                            {trnsl[trnsl.user_lang].at_you}: {count}  {/* {trnsl[trnsl.user_lang].scrolling} (UTC{utcOffset >= 0 ? '+' : ''}{utcOffset} */}
                        </div>
                        {count == 0 ? (
                            <div className='down_message_inner_wrap'>
                                {timeLeft}
                            </div>
                        ) : null}
                    </div>
                ) : drumcondition === true ? (
                    <div className='down_message_inner_wrap'>
                        {trnsl[trnsl.user_lang].wait}
                    </div>
                ) : null}

                {/* НАДА ВОТКНУТЬ КУДА НИТЬ ТАЙМЕР
                <div className='down_message_inner_wrap'>
                    {timeLeft} до пополнения (UTC{utcOffset >= 0 ? '+' : ''}{utcOffset})
                </div>
                 */}
            </div>
        </>
    );
});

export default DownMessage;

import React, { useEffect, useRef, useState } from 'react';
import { observer } from "mobx-react-lite";

//Стили
import '../css/slidebottompanel.css';

const SlideBottomPanel = observer(({ isOpen, onClose, children }) => {
    return (
        <>
            {/* Фон, затемняющий остальную часть контента */}
            {isOpen && <div className="backdrop" onClick={onClose}></div>}

            {/* Сама панель */}
            <div className={`slide-bottom-panel ${isOpen ? 'open' : ''}`}>
                {children}
            </div>
        </>
    );
});

export default SlideBottomPanel;

import React, { useEffect, useRef, useState } from 'react';
import { observer } from "mobx-react-lite";

//Стили
import '../css/reklama.css';

//Сторы
import PlayerStore from '../store/playerStore';

const Reklama = observer(() => {
    return (
        <>
            <div className='reklama'>
                <img src='/images/bybit.png' />
            </div>
        </>
    );
});

export default Reklama;

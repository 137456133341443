import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { observer } from "mobx-react-lite";
import { Modal } from 'react-bootstrap';

import { TonConnectButton, useTonAddress, useTonConnectUI, useTonWallet, TonConnect } from '@tonconnect/ui-react';

//Конфиг
import config from '../config';

//Стили
import '../css/iduser.css';
import '../css/referal_links.css';

//Языки / переводы / сторы
import translationStore from '../locales/locales';
import PlayerStore from '../store/playerStore';


//Компоненты
import TopHeader from '../components/TopHeader';

//Toast сообщения
import { ToastContainer, toast, Bounce, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import playerStore from '../store/playerStore';


const Iduser = observer(() => {

    //Транзакция===
    const [tonConnectUITransa] = useTonConnectUI({
        manifestUrl: 'https://vabankgame.ru/tonconnect-manifest.json', // Укажите URL вашего манифеста
        network: 'mainnet', // Явно указываем Testnet testnet mainnet
    });


    // Создаем транзакцию с данными
    const [tonValue, setTonValue] = useState(0);
    const tonTransaction = tonValue * 1000000000;
    const transaction = {
        validUntil: Date.now() + 5 * 60 * 1000, // Транзакция будет валидна в течение 5 минут
        messages: [
            {
                address: "UQCz5viJGugYwk-W-cGjkcTG8XOS_ECstnreZmGwRppzzBbr", // Адрес получателя
                amount: tonTransaction, // Сумма в нанотоннах (0.02 Toncoin)
            },
        ],
    };

    // Функция отправки транзакции
    const [nanoTon, setNanoTon] = useState(0);
    const handleSendTransaction = async () => {
        setOpenModalAdd(false);

        if (wallet?.account?.chain !== '-1' && wallet?.account?.chain !== '-239') {
            alert('Transaction is allowed only on the mainnet.');
            return;
        }
        try {
            await tonConnectUITransa.sendTransaction(transaction);
            // После успешной отправки выводим сумму и адрес
            console.log('Transaction sent successfully!');
            console.log(`Address: ${transaction.messages[0].address}`);
            console.log(`Amount: ${transaction.messages[0].amount} nanotons`);
            setNanoTon(transaction.messages[0].amount);
            confirmTransaction(transaction.messages[0].amount);
            alert('Transaction sent successfully!');
        } catch (error) {
            console.error('Error sending transaction:', error);
            alert('Failed to send transaction.');
        }
    };
    //==============

    //const wallet = useTonWallet();//текущий кошелёк пользователя

    //Языки / переводы
    const tr = translationStore.trnsl;

    ////Для кошелька
    const userFriendlyAddress = useTonAddress();
    const rawAddress = useTonAddress(false);

    const linkRef = useRef(null); //Ссылка на инпут для копирования
    const linkTwoRef = useRef(null); //Ссылка на инпут для копирования
    const copyToClipboard = (lRef) => {
        if (lRef.current) {
            lRef.current.select();
            document.execCommand('copy');
            toast.success(tr[tr.user_lang].support_copy_link, {
                position: "bottom-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Slide,
            });
        }
    };

    const [isConnected, setIsConnected] = useState(false);
    const [tonConnectUI, setTonConnectUI] = useTonConnectUI({
        manifestUrl: 'https://vabankgame.ru/tonconnect-manifest.json', // Укажите URL вашего манифеста
        network: 'testnet', // Явно указываем Testnet
    });

    const wallet = useTonWallet();//Информация о кошельке 
    console.log(wallet);
    useEffect(() => {
        // Проверка статуса подключения
        if (tonConnectUI.account) {
            setIsConnected(true); // Пользователь подключен
            console.log('tonConnectUI.account --->', tonConnectUI.account);
            console.log('useTonWallet --->', JSON.stringify(wallet, null, 1));
        } else {
            setIsConnected(false); // Кошелек не подключен
            //toast.info("Кошелёк отключён!", {
            //    position: "bottom-center",
            //    autoClose: 3000,
            //    hideProgressBar: false,
            //    closeOnClick: true,
            //    pauseOnHover: true,
            //    draggable: true,
            //    progress: undefined,
            //    theme: "dark",
            //    transition: Slide,
            //});
        }
    }, [tonConnectUI.account]); // Следим за изменением аккаунта

    //Функция для записи тон кошелька
    const saveTonUser = () => {
        const apiUrl = config.apiBaseUrl + '/api/api/v1/usertonsave';
        const token = PlayerStore.player.token; // Получаем токен

        // Заголовки запроса
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Добавляем токен в заголовок Authorization
        };
        //Отправляемые даныне
        const dataRec = {
            "idchat": PlayerStore.player.idchat,
            "friendly_address": userFriendlyAddress,
            "raw_address": rawAddress
        };

        axios.post(apiUrl, dataRec, { headers })
            .then(response => {
                console.log('Кошелёк сохранён!: ', JSON.stringify(response.data, null, 1));
            })
            .catch(error => {
                if (error.response) {
                    // Сервер ответил статусом, который выходит за пределы диапазона 2xx
                    console.error('Ошибка  2xx:', error.response.data);
                } else if (error.request) {
                    // Запрос был сделан, но ответ не был получен
                    console.error('Ошибка запроса:', error.request);
                } else {
                    // Ошибка при настройке запроса
                    console.error('Ошибка:', error.message);
                }
            });
    }

    useEffect(() => {
        saveTonUser();
    }, [tonConnectUI.account, userFriendlyAddress]); // Следим за изменением аккаунта

    //Функция пополнения баланся usdt
    const usdtAdd = () => {
        console.log('Пополнить баланс!');
    }

    //Модальное окно добавить usdt
    const [openModalAdd, setOpenModalAdd] = useState(false);
    const [cursTon, setCursTon] = useState(0);
    const [openModalWithdraw, setOpenModalWithdraw] = useState(false);

    const handleOpenModalAdd = () => {
        getCursTon();
        setOpenModalAdd(true);
    }

    const handleCloseModalAdd = () => {
        setOpenModalAdd(false);
    }

    const handleOpenModalWithdraw = () => {
        getCursTon();
        setOpenModalWithdraw(true);
    }

    const handleCloseModalWithdraw = () => {
        setOpenModalWithdraw(false);
    }

    //На покупку usdt 
    const [usdtValue, setUsdtValue] = useState(0);
    const handlerTonValue = (value) => {
        setTonValue(value);

        let usdt = (cursTon * value).toFixed(3); // Получаем строку с 3 знаками после запятой
        if (isNaN(usdt)) {
            usdt = 0;
        } else {
            usdt = parseFloat(usdt); // Преобразуем строку обратно в число
        }

        setUsdtValue(usdt); // Используем новое значение для расчёта
        console.log('tonValue: ', value);
    };


    //Запрос на курс ТОН
    const getCursTon = () => {
        const apiUrl = config.apiBaseUrl + '/api/api/v1/getcurstokens';
        const token = PlayerStore.player.token; // Получаем токен

        // Заголовки запроса
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Добавляем токен в заголовок Authorization
        };
        //Отправляемые данные
        const dataRec = {

        };

        axios.post(apiUrl, { headers })
            .then(response => {
                console.log('Курс TON: ', JSON.stringify(response.data, null, 1));
                setCursTon(response.data);
            })
            .catch(error => {
                if (error.response) {
                    // Сервер ответил статусом, который выходит за пределы диапазона 2xx
                    console.error('Ошибка  2xx:', error.response.data);
                } else if (error.request) {
                    // Запрос был сделан, но ответ не был получен
                    console.error('Ошибка запроса:', error.request);
                } else {
                    // Ошибка при настройке запроса
                    console.error('Ошибка:', error.message);
                }
            });
    }

    //Подтвердить транзакцию
    const confirmTransaction = (value) => {
        console.log('Подтвердить транзакцию!', value);

        const apiUrl = config.apiBaseUrl + '/api/api/v1/exchangington';
        const token = PlayerStore.player.token; // Получаем токен

        // Заголовки запроса
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Добавляем токен в заголовок Authorization
        };
        //Отправляемые данные
        const dataRec = {
            "idchat": PlayerStore.player.idchat,
            "ton": tonValue,
        };

        axios.post(apiUrl, dataRec, { headers })
            .then(response => {
                console.log('Транзакция: ', JSON.stringify(response.data, null, 1));
                playerStore.editPlayerStore('usdt', response.data.user.usdt);
            })
            .catch(error => {
                if (error.response) {
                    // Сервер ответил статусом, который выходит за пределы диапазона 2xx
                    console.error('Ошибка  2xx:', error.response.data);
                } else if (error.request) {
                    // Запрос был сделан, но ответ не был получен
                    console.error('Ошибка запроса:', error.request);
                } else {
                    // Ошибка при настройке запроса
                    console.error('Ошибка:', error.message);
                }
            });
    }

    //Модальное окно обменять usdt на vabank
    const [openModalExchange, setOpenModalExchange] = useState(false);
    const [vbkValue, setVbkValue] = useState(0);
    const [vbkNew, setVbkNew] = useState(0);

    const handleCloseModalExchange = () => {
        setOpenModalExchange(false);
    }

    const handlerVbkValue = (value) => {
        setVbkValue(value);
        let cursVbk = 1;
        let vbk = (value / cursVbk).toFixed(3); // Получаем строку с 3 знаками после запятой
        if (isNaN(vbk)) {
            vbk = 0;
        } else {
            vbk = parseFloat(vbk); // Преобразуем строку обратно в число
        }

        setVbkNew(vbk); // Используем новое значение для расчёта
        console.log('vbkValue: ', value);
    };


    //Функция обмена запроса к серверу на обмен USDT на VBK
    const exchangingusdttovbk = () => {

        const apiUrl = config.apiBaseUrl + '/api/api/v1/exchangingusdttovbk';
        const token = PlayerStore.player.token; // Получаем токен

        // Заголовки запроса
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Добавляем токен в заголовок Authorization
        };
        //Отправляемые данные
        const dataRec = {
            "idchat": PlayerStore.player.idchat,
            "vbk": vbkValue,
        };

        axios.post(apiUrl, dataRec, { headers })
            .then(response => {
                console.log('Транзакция обмена USDT на VBK: ', JSON.stringify(response.data, null, 1));
                if (response.data.status == 'success') {
                    setOpenModalExchange(false);
                    setTimeout(() => {
                        playerStore.editPlayerStore('vbk', response.data.vbk);
                        playerStore.editPlayerStore('usdt', response.data.usdt);
                        toast.success(response.data.message, {
                            position: "bottom-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "dark",
                            transition: Slide,
                        });
                    }, 1500); //Обновить будет возможно раз в 3 секунды
                }
                if (response.data.status == 'error') {
                    toast.error(response.data.message, {
                        position: "bottom-center",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                        transition: Slide,
                    });
                }

            })
            .catch(error => {
                if (error.response) {
                    // Сервер ответил статусом, который выходит за пределы диапазона 2xx
                    console.error('Ошибка  2xx:', error.response.data);
                } else if (error.request) {
                    // Запрос был сделан, но ответ не был получен
                    console.error('Ошибка запроса:', error.request);
                } else {
                    // Ошибка при настройке запроса
                    console.error('Ошибка:', error.message);
                }
            });
    }

    //Вывести USDT в TON
    const [usdtInput, setUsdtInput] = useState(0);
    const [usdtToTonValue, setUsdtToTonValue] = useState(0);
    const [usdtToTon, setUsdtToTon] = useState(0);
    const handlerUsdtValue = (value) => {
        setUsdtInput(value);

        let usdtToTon = (value / cursTon).toFixed(3); // Получаем строку с 3 знаками после запятой
        if (isNaN(usdtToTon)) {
            usdtToTon = 0;
            setUsdtToTon(usdtToTon);
        } else {
            usdtToTon = parseFloat(usdtToTon); // Преобразуем строку обратно в число
            setUsdtToTon(usdtToTon);
        }


        console.log('usdtToTon: ', usdtToTon);
    };

    const handleSendTransactionUsdtToTon = () => {
        console.log('Подтвердить транзакцию! USDT в TON');

        const apiUrl = config.apiBaseUrl + '/api/api/v1/withdrawalusdt';
        const token = PlayerStore.player.token; // Получаем токен

        // Заголовки запроса
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Добавляем токен в заголовок Authorization
        };
        //Отправляемые данные
        const dataRec = {
            "idchat": PlayerStore.player.idchat,
            "amount": usdtInput,
        };

        axios.post(apiUrl, dataRec, { headers })
            .then(response => {
                console.log('Транзакция USDT to TON: ', JSON.stringify(response.data, null, 1));
                if (response?.data?.status == 'success') {
                    playerStore.editPlayerStore('usdt', response?.data?.new_balance_usdt);
                    handleCloseModalWithdraw();
                    toast.success(response?.data?.message, {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                        transition: Slide,
                    });
                }
                if (response?.data?.status == 'error') {
                    toast.error(response?.data?.message, {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                        transition: Slide,
                    });
                }
            })
            .catch(error => {
                if (error.response) {
                    // Сервер ответил статусом, который выходит за пределы диапазона 2xx
                    console.error('Ошибка  2xx:', error.response.data);
                } else if (error.request) {
                    // Запрос был сделан, но ответ не был получен
                    console.error('Ошибка запроса:', error.request);
                } else {
                    // Ошибка при настройке запроса
                    console.error('Ошибка:', error.message);
                }
            });

    }

    return (
        <>
            <div className='slot_machine_flex_top'>
                <TopHeader />
            </div>
            {/*
            <div className='iduser_main'>
                <img style={{ width: 50, height: 40 }} src="/images/moneta.png" alt="" />
                <div style={{ fontSize: 28, fontWeight: 'bold' }}>COMING SOON</div>
            </div>
            */}

            <div className='iduser_main'>
                <div className='iduser_mainwrap'>
                    <img src="/images/moneta.png" style={{ width: '80px', height: '60px' }} />

                    <div className="iduser_title">
                        {tr[tr.user_lang].airdrop_wallet}
                    </div>

                    <TonConnectButton className="iduser_button_ton" />

                    <div>
                        {userFriendlyAddress ? tr[tr.user_lang].wallet_is_connected : tr[tr.user_lang].wallet_is_not_connected}
                    </div>


                    {userFriendlyAddress && (
                        <div className='iduser_ton'>
                            <div className='iduser_ton_address'>
                                <div className='iduser_ton_lable'>User-friendly address: </div>
                                <div className='referal_link_form'>

                                    <div className='referal_link_row' style={{ display: 'flex', alignItems: 'center' }}>
                                        <input
                                            ref={linkRef}
                                            value={userFriendlyAddress}
                                            readOnly
                                            className='referal_link_input'
                                        />
                                        <button
                                            onClick={() => copyToClipboard(linkRef)}
                                            className='referal_link_copybutton'
                                        >
                                            <img src='/images/icon-copy.svg' className='icon-copy' />
                                        </button>
                                    </div>

                                </div>
                            </div>
                            {/*
                            <div className='iduser_ton_address'>
                                <div className='iduser_ton_lable'>Raw address:</div>
                                <div className='referal_link_row' style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                        ref={linkTwoRef}
                                        value={rawAddress}
                                        readOnly
                                        className='referal_link_input'
                                    />
                                    <button
                                        onClick={() => copyToClipboard(linkTwoRef)}
                                        className='referal_link_copybutton'
                                    >
                                        <img src='/images/icon-copy.svg' className='icon-copy' />
                                    </button>
                                </div>
                            </div>
                             */}
                        </div>
                    )}

                    {/*
                    <button onClick={handleSendTransaction}>
                        Send transaction test
                    </button>
                    */}

                    {/* USDT */}
                    <div className='iduser_usdtWrap'>
                        <div className='iduser_usdt_label'>{tr[tr.user_lang].user_usdt_balance}</div>
                        <div className='iduser_usdt'>
                            {playerStore?.player?.usdt}
                            <img src="/images/usdt.png" alt="" className='usdtImage' />
                            {userFriendlyAddress && (
                                <div className='button_absolute_right flex_row'>
                                    <button
                                        onClick={() => handleOpenModalAdd()} // Передаем индекс в функцию
                                        className='iduser_button'
                                    >
                                        {tr[tr.user_lang].usdt_add} <img style={{ width: 22, height: 22, color: '#ffffff', marginLeft: 5 }} src='/images/ios-card.svg' />
                                    </button>
                                    <button
                                        onClick={() => handleOpenModalWithdraw()} // Передаем индекс в функцию
                                        className='iduser_button'
                                    >
                                        {tr[tr.user_lang].withdraw} <img style={{ width: 22, height: 22, color: '#ffffff', marginLeft: 5 }} src='/images/ios-undo.svg' />
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                    {/* USDT END*/}

                    {/* VBK */}
                    <div className='iduser_usdtWrap'>
                        <div className='iduser_usdt_label'>{tr[tr.user_lang].user_vbk_balance}</div>
                        <div className='iduser_usdt'>
                            {playerStore?.player?.vbk}
                            <img src="/images/vbk.png" alt="" className='usdtImage' />
                            {userFriendlyAddress && (
                                <button
                                    onClick={() => setOpenModalExchange(true)} // Передаем индекс в функцию
                                    className='iduser_button button_absolute_right'
                                >
                                    {tr[tr.user_lang].usdt_exchange} <img style={{ width: 22, height: 22, color: '#ffffff', marginLeft: 5 }} src='/images/ios-shuffle.svg' />
                                </button>
                            )}
                        </div>
                    </div>
                    {/* VBK END */}


                    <div style={{ color: 'yellow' }}>
                        {/*wallet ? (
                            <>
                                <div>Connected via: {wallet.provider}</div>
                                <div>Device: {wallet.device.appName}</div>
                                <div>Connected via: {wallet.provider}</div>
                                {wallet.connectItems?.tonProof?.proof && <div>Ton proof: {wallet.connectItems.tonProof.proof}</div>}
                                <div>Connected wallet info:</div>
                                <div>
                                    {wallet.name} <img style={{ width: 30 }} src={wallet.imageUrl} />
                                </div>
                            </>
                        ) : 'нет'*/}


                    </div>
                </div>
            </div>

            <Modal
                show={openModalAdd}
                onHide={handleCloseModalAdd} top>
                <button className='buttonClose' onClick={handleCloseModalAdd}>
                    <img src='images/close.png' />
                </button>
                <div className='modalBody'>
                    <div className='modalBodyTitle'>
                        {tr[tr.user_lang].usdt_add}
                    </div>

                    <div className='cursTitle'>TON : USDT</div>
                    <div className='curs'>{cursTon} : 1</div>

                    <div className='cursTitle'>{tr[tr.user_lang].exchange_ton}</div>

                    <div className='cursInputWrap'>
                        <input
                            type="number"
                            value={tonValue}
                            onChange={(e) => handlerTonValue(e.target.value)}
                            className='iduser_input'
                        />
                    </div>

                    <div className='cursOutputWrap'>
                        {tr[tr.user_lang].will_receive}:
                        <div className='cursVbkAmount'>{usdtValue} USDT</div>
                    </div>

                    <div className='cursButtonConfirmWrap'>
                        <button
                            onClick={() => handleSendTransaction()}
                            className='button_confirm'
                        >
                            <img style={{ width: 25, height: 25, color: '#ffffff', marginRight: 5 }} src='/images/ios-checkmark-circle-outline.svg' /> {tr[tr.user_lang].button_text_confirm}
                        </button>
                    </div>
                </div>
            </Modal>

            <Modal
                show={openModalExchange}
                onHide={handleCloseModalExchange} top>
                <button className='buttonClose' onClick={handleCloseModalExchange}>
                    <img src='images/close.png' />
                </button>
                <div className='modalBody'>
                    <div className='modalBodyTitle'>
                        {tr[tr.user_lang].usdt_exchange}
                    </div>


                    <div className='cursTitle'>USDT : VBK</div>
                    <div className='curs'>1 : 1</div>

                    <div className='cursTitle'>{tr[tr.user_lang].exchange_vbk}</div>

                    <div className='cursInputWrap'>
                        <input
                            type="number"
                            value={vbkValue}
                            onChange={(e) => handlerVbkValue(e.target.value)}
                            className='iduser_input'
                        />
                    </div>

                    <div className='cursOutputWrap'>
                        {tr[tr.user_lang].will_receive}:
                        <div className='cursVbkAmount'>{vbkNew} USDT</div>
                    </div>

                    <div className='cursButtonConfirmWrap'>
                        <button
                            onClick={() => exchangingusdttovbk()}
                            className='button_confirm'
                        >
                            <img style={{ width: 25, height: 25, color: '#ffffff', marginRight: 5 }} src='/images/ios-checkmark-circle-outline.svg' /> {tr[tr.user_lang].button_text_confirm}
                        </button>
                    </div>
                </div>
            </Modal>

            <Modal
                show={openModalWithdraw}
                onHide={handleCloseModalWithdraw} top>
                <button className='buttonClose' onClick={handleCloseModalWithdraw}>
                    <img src='images/close.png' />
                </button>
                <div className='modalBody'>
                    <div className='modalBodyTitle'>
                        {tr[tr.user_lang].withdraw}
                    </div>

                    <div className='cursTitle'>{tr[tr.user_lang].withdraw_usdt}</div>

                    <div className='cursInputWrap'>
                        <input
                            type="number"
                            value={usdtInput}
                            onChange={(e) => handlerUsdtValue(e.target.value)}
                            className='iduser_input'
                        />
                    </div>

                    <div className='cursOutputWrap'>
                        {tr[tr.user_lang].will_receive}:
                        <div className='cursVbkAmount'>{usdtToTon} TON</div>
                    </div>

                    <div className='transaction_info'>{tr[tr.user_lang].transaction_info}</div>

                    <div className='cursButtonConfirmWrap'>
                        <button
                            onClick={() => handleSendTransactionUsdtToTon()}
                            className='button_confirm'
                        >
                            <img style={{ width: 25, height: 25, color: '#ffffff', marginRight: 5 }} src='/images/ios-checkmark-circle-outline.svg' /> {tr[tr.user_lang].button_text_confirm}
                        </button>
                    </div>
                </div>
            </Modal>

            <ToastContainer />
        </>
    );
})

export default Iduser;

import React from 'react';
import { observer } from "mobx-react-lite";
import { Routes, Route, Link } from 'react-router-dom';

//Стили
import '../css/templatereferal.css';

//Языки / переводы
import translationStore from '../locales/locales';


const TemplateReferalTwoLine = observer(({ referal }) => {
    //Языки / переводы
    const trnsl = translationStore.trnsl;

    // Обработчик события onError для замены изображения на дефолтное
    const handleImageError = (event) => {
        event.target.src = '/images/ava_default.png';
    };

    return (
        <>
            {Array.isArray(referal?.referals) && referal?.referals.map(ref => (
                <div className='referal_item'>
                    <div className='referal_item_row'>
                        <div className='referal_item_image'>
                            <img
                                src={ref?.user_photo ? ref?.user_photo : '/images/ava_default.png'}
                                className='referal_user_avatar'
                                onError={handleImageError}
                            />
                        </div>
                        <div className='referal_item_name'>
                            <div>
                                <div className='referal_item_name_user'>
                                    {ref?.username ? ref?.username : "no name"}
                                </div>
                            </div>
                        </div>
                        <div className='referal_item_rew'>
                            <div className='referal_item_rewmonets'>
                                {ref?.balance} <img src='/images/moneta.png' className='referal_item_icon_monets' />
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
});

export default TemplateReferalTwoLine;

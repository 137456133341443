import React from 'react';
import { observer } from "mobx-react-lite";
import { Routes, Route, Link } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';

//Стили
import '../css/balance.css';

//Языки / переводы
import translationStore from '../locales/locales';
import playerStore from '../store/playerStore';



const Balance = observer(() => {
    //Языки / переводы
    const trnsl = translationStore.trnsl;

    // Получаем текущий баланс из playerStore
    const currentBalance = playerStore.player.balance;

    // Настройки анимации
    const props = useSpring({ from: { number: 0 }, number: currentBalance, delay: 1200 });


    return (
        <>
            <div className='balance_wrap'>
                <div className='balance_text'>
                    {trnsl[trnsl.user_lang].you_balance}:
                </div>
                <div className='balance_number'>
                    <animated.span>{props.number.to(n => n.toFixed(0))}</animated.span> <span><img src={'/images/moneta.png'} alt='' /></span>
                </div>
            </div>
        </>
    );
});

export default Balance;

import React, { useEffect, useRef, useState } from 'react';
import '../css/slot-machine.css';
import { observer } from "mobx-react-lite";
import styled from 'styled-components';

import axios from 'axios';

//Конфиг
import config from '../config';

//Сторы
import PlayerStore from '../store/playerStore';

//Компоненты
import DownMessage from './DownMessage';
import WinMonets from '../components/WinMonets';
import CoinAnimation from '../components/CoinAnimation';


//Для анимации монет при выгрыше
const AppContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: fixed;
  top:0;
  left:0;
  right:0;
  margin:auto;
  z-index:3
`;
const AppContainerWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width:100%;
  position: relative;
`;
const TargetDiv = styled.div`
    width: 30px;
    height: 30px;
    position: absolute;
    top: 100px;
    right: 50%;
`;

const Slots = observer(() => {
    const slotMachineRef = useRef(null);
    const slotMachineInstance = useRef(null);
    //const [winnings, setWinnings] = useState(PlayerStore.player.scrolling);
    const [buttonPlayDisabled, setButtonPlayDisabled] = useState(false); //Для блокировки кнопки во время спина
    const [balance, setBalance] = useState(null);
    const [winning, setWinning] = useState(0); //Выигрыш
    const [postUpdateUser, setPostUpdateUser] = useState(false); //Отправка данных на сервер
    const [whenScrolling, setWhenScrolling] = useState(false); //true это когда происходит прокрутка барабана, true когда барабоат стоит


    //Для анимации монет
    const targetRef = useRef(null);
    const [trigger, setTrigger] = useState(false);//запуск анимации летящих монет

    // Функция для обновления выигрыша и синхронизации со Store
    const updateWinnings = (winAmount) => {
        const newWinnings = PlayerStore.player.scrolling + winAmount;
        PlayerStore.editPlayerStore('scrolling', newWinnings);

        setPostUpdateUser(true); //Обновляем даныне пользователя
        setTimeout(() => {
            setPostUpdateUser(false);//Обновляем данные пользователя
        }, 200);
    };

    useEffect(() => {
        setBalance(PlayerStore.player.balance);
    }, [PlayerStore.player.balance]);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = '/js/slot-machine.js';
        script.async = true;

        script.onload = () => {
            if (slotMachineRef.current && !document.getElementById('slot-machine')) {
                initializeSlotMachine(PlayerStore.player.reels);
            } else if (document.getElementById('slot-machine')) {
                console.error('Slot machine container already exists.');
            } else {
                console.error('Slot machine container not found.');
            }
        };

        script.onerror = () => {
            console.error('Failed to load slot-machine.js');
        };

        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const initializeSlotMachine = (reelsMass) => {//В reelsMass попадает reels из стора плеера
        console.log('reelsMass::: ', JSON.stringify(reelsMass));
        const reels = reelsMass;

        const options = {
            click2Spin: false,
            reels: {
                duration: 3000, // Продолжительность анимации прокрутки в миллисекундах
                easing: 'ease-out', // Тип анимации
            },
            sounds: {
                reelsBegin: '/sounds/reelsBegin.mp3',
                reelsEnd: '/sounds/reelsEnd.mp3',
            }
        };

        const callback = (payLine) => {

            //Комбинация=====
            console.log(payLine[0].title + ' | ' + payLine[1].title + ' | ' + payLine[2].title);
            //Комбинация===== END

            setButtonPlayDisabled(false);//Разблокируем кнопку после спина
            setWhenScrolling(false); //Барабан остановился
            //Тройные совпадения
            if (payLine[0].title === payLine[1].title && payLine[0].title === payLine[2].title) {
                (new Audio('/sounds/winner.mp3')).play();

                const { winnings: winAmount, winning: displayWinning } = PlayerStore.player.winningValuesThree[payLine[0].title] || { winnings: 0, winning: 0 };

                console.log('Совпали все три: ', payLine[0].title);

                //setWinnings(prevWinnings => prevWinnings + winAmount);
                updateWinnings(winAmount); //Прокруты
                setBalance(prevBalance => prevBalance + displayWinning);
                setWinning(displayWinning);
                handleClick(true);

                setTimeout(() => {
                    setWinning(0);
                }, 5000);

            }
            //Двойные совпадения
            const titles = payLine.map(symbol => symbol.title);
            // Условие для проверки двух одинаковых и одного отличающегося
            const twoSameOneDifferent =
                (titles[0] === titles[1] && titles[0] !== titles[2]) ||
                (titles[0] === titles[2] && titles[0] !== titles[1]) ||
                (titles[1] === titles[2] && titles[1] !== titles[0]);

            if (twoSameOneDifferent) {
                (new Audio('/sounds/winner.mp3')).play();

                // Находим символ, который повторяется
                let winningSymbol;
                if (titles[0] === titles[1]) {
                    winningSymbol = titles[0];
                } else if (titles[0] === titles[2]) {
                    winningSymbol = titles[0];
                } else {
                    winningSymbol = titles[1];
                }

                console.log('Совпали: ', winningSymbol);

                const { winnings: winAmount, winning: displayWinning } = PlayerStore.player.winningValuesTwo[winningSymbol] || { winnings: 0, winning: 0 };

                //setWinnings(prevWinnings => prevWinnings + winAmount);
                updateWinnings(winAmount); //Прокруты
                setBalance(prevBalance => prevBalance + displayWinning);
                setWinning(displayWinning);
                handleClick(true);

                setPostUpdateUser(true); //Обновляем даныне пользователя
                setTimeout(() => {
                    setWinning(0);
                    setPostUpdateUser(false);//Обновляем данные пользователя
                }, 5000);

            }
        };
        slotMachineInstance.current = window.slotMachine(slotMachineRef.current, reels, callback, options);
    };

    const handleButtonClick = () => {
        if (PlayerStore.player.scrolling > 0 && slotMachineInstance.current) {
            slotMachineInstance.current.play();
        }
        else if (PlayerStore.player.scrolling === 0) {
            return;
        }
        else {
            return;
        }
        //setWinnings(prevWinnings => prevWinnings - 1);
        updateWinnings(-1);
        setButtonPlayDisabled(true);//Блокируем кнопку во время спина
        setWhenScrolling(true); //Включаем барабан (барабан крутится)
    };

    //Функция обновления пользователя после выйгрыша
    const updateUserWinner = () => {
        const apiUrl = config.apiBaseUrl + '/api/api/v1/winner';
        const idchat = String(PlayerStore.player.idchat); // Получаем idchat
        const balance = PlayerStore.player.balance; // Получаем баланс
        const scrolling = PlayerStore.player.scrolling; // Получаем scrolling
        const level = PlayerStore.player.level; // Получаем уровень
        const rank = String(PlayerStore.player.rank); // Получаем ранг
        const token = PlayerStore.player.token; // Получаем токен

        // Определяем часовой пояс пользователя
        const timezoneOffset = getUserTimezoneOffset();

        // Заголовки запроса
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Добавляем токен в заголовок Authorization
        };

        const dataRec = {
            "idchat": idchat,
            "balance": balance,
            "scrolling": scrolling,
            "level": level,
            "rank": rank,
            "timezone": timezoneOffset // Добавляем часовой пояс
        };

        axios.post(apiUrl, dataRec, { headers })
            .then(response => {
                console.log("ПОЛЬЗОВАТЕЛЬ ОБНОВЛЁН: ", JSON.stringify(response.data, null, 1));
                //PlayerStore.setPlayer(response.data.user);

                // Получаем время из response
                let timeScrolling = response.data.message.time_scrolling; // Предположим, это строка времени в формате ISO

                // Преобразуем time_scrolling в объект Date
                let timeScrollingDate = new Date(timeScrolling);

                // Определяем разницу в часах из временной зоны
                const timezoneOffsetHours = timezoneOffset / 60; // Переводим минуты в часы

                // Вычисляем новое время
                timeScrollingDate.setHours(timeScrollingDate.getHours() - response.data.dataRes.timezone);

                // Преобразуем время обратно в строку ISO
                let adjustedTimeScrolling = timeScrollingDate.toISOString();
                console.log('timezoneOffsetHours====> ', PlayerStore.player.timezone);

                // Обновляем PlayerStore
                PlayerStore.editPlayerStore('time_scrolling', adjustedTimeScrolling);
                //PlayerStore.editPlayerStore('timezone', response.data.message.timezone);

                //// Обновляем PlayerStore
                ////PlayerStore.editPlayerStore('tguser', tg.initDataUnsafe.user);
                //PlayerStore.editPlayerStore('time_scrolling', response.data.message.time_scrolling);
                //PlayerStore.editPlayerStore('timezone', response.data.message.timezone);
            })
            .catch(error => {
                if (error.response) {
                    // Сервер ответил статусом, который выходит за пределы диапазона 2xx
                    console.error('Ошибка:', error.response.data);
                } else if (error.request) {
                    // Запрос был сделан, но ответ не был получен
                    console.error('Ошибка запроса:', error.request);
                } else {
                    // Ошибка при настройке запроса
                    console.error('Ошибка:', error.message);
                }
            });
    }

    //Определяем часовую зону в часах
    const getUserTimezoneOffset = () => {
        const offset = new Date().getTimezoneOffset(); // Получаем смещение в минутах
        return -offset / 60; // Преобразуем в часы (отрицательное значение преобразуем в положительное)
    };



    //Проверяем уровень пользователя==============
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    const levels = PlayerStore.player.levels;

    useEffect(() => {
        PlayerStore.editPlayerStore('scrolling', PlayerStore.player.scrolling);
        PlayerStore.editPlayerStore('balance', balance);
        const checkingLevelUser = async () => {
            try {
                setLoading(true); // Устанавливаем состояние загрузки в true
                const balance = PlayerStore.player.balance;

                for (const level of levels) {
                    if (balance >= level.minBalance && balance < level.maxBalance) {
                        PlayerStore.editPlayerStore('level', level.level);
                        PlayerStore.editPlayerStore('rank', level.rank);
                        break; // Выходим из цикла, как только найден соответствующий уровень
                    }
                }
            } catch (error) {
                setError('Ошибка проверки уровня пользователя! ', error.message); // Устанавливаем ошибку в состояние
            } finally {
                setLoading(false); // Устанавливаем состояние загрузки в false, независимо от результата
            }
        };

        checkingLevelUser(); // Вызоваем функцию обновления уровня пользователя

        // Очистка (если требуется)
        return () => {

        };
    }, [postUpdateUser]);
    //============================================

    //ОБНОВЛЕНИЕ ПОЛЬЗОВАТЕЛЯ
    //useEffect(() => {
    //    if (postUpdateUser === true) {
    //        updateUserWinner();// Вызоваем функцию запроса на сервер для обновления данных пользователя
    //    }
    //}, [postUpdateUser]);


    //Запускаем анимацию летящих монет при выйгрыше
    const handleClick = () => {
        setTrigger(true);
        setTimeout(() => {
            setTrigger(false);
        }, 5000);
    };

    //Функция которая сработает если закончатся прокроту(спины слоты)
    //const handlerSlots = () => {
    //    updateUserWinner();// Вызоваем функцию запроса на сервер для обновления данных пользователя

    //    //const currentTime = new Date();
    //    //const utcCurrentTime = new Date(currentTime.getTime() + (currentTime.getTimezoneOffset() * 60000));
    //    //const padZero = (num) => num.toString().padStart(2, '0');
    //    //const formattedDate = `${utcCurrentTime.getUTCFullYear()}-${padZero(utcCurrentTime.getUTCMonth() + 1)}-${padZero(utcCurrentTime.getUTCDate())} ${padZero(utcCurrentTime.getUTCHours())}:${padZero(utcCurrentTime.getUTCMinutes())}:${padZero(utcCurrentTime.getUTCSeconds())}`;
    //    //PlayerStore.editPlayerStore('time_scrolling', formattedDate);

    //    ////const currentTime = new Date();
    //    ////console.log('currentTime===> ', currentTime);
    //    ////PlayerStore.editPlayerStore('time_scrolling', currentTime);
    //    //PlayerStore.editPlayerStore('scrolling', 0);
    //}

    //Состояние барабана (крутится или нет)
    useEffect(() => {
        if (whenScrolling === true) {
            console.log('БАРАБАН КРУТИТСЯ!');
        }
        else {
            console.log('БАРАБАН ОСТАНОВИЛСЯ!');
            updateUserWinner();
        }
    }, [whenScrolling]);

    return (
        <div className='slot_machine_wrap_screen'>

            <WinMonets winning_monets={winning} />

            <div ref={slotMachineRef} className="slot-machine"></div>
            <div className='slot_machine_wrap_strlk'>
                <div className='slot_machine_wrap_inner_strlk'>
                    <div className='slot_machine_strlk_left'></div>
                    <div className='slot_machine_strlk_right'></div>
                </div>
            </div>

            <div className='slot_machine_wrap_button'>
                <button className='play_button' id="play-button" onClick={handleButtonClick} disabled={buttonPlayDisabled}></button>
                <DownMessage countSlots={PlayerStore.player.scrolling} drumcondition={whenScrolling} />
            </div>
            {trigger === true ? (
                <AppContainer>
                    <AppContainerWrap>
                        <TargetDiv ref={targetRef} />
                        <CoinAnimation targetRef={targetRef} trigger={trigger} />
                    </AppContainerWrap>
                </AppContainer>
            ) : null}
        </div>
    );
});

export default Slots;

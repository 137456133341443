import axios from 'axios';
import { makeAutoObservable } from "mobx";
import PlayerStore from '../store/playerStore';

// Конфиг
import config from '../config';

//Toast сообщения
import { ToastContainer, toast, Bounce, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class TasksStore {

    tasks = []
    onTaskUpdate = null; // Callback для обновления задачи

    //Для обновления стейта в Tasks.js
    setTaskUpdateCallback(callback) {
        this.onTaskUpdate = callback;
    }

    notifyTaskUpdate(task) {
        if (this.onTaskUpdate) {
            this.onTaskUpdate(task);
        }
    }

    // Делаем отслеживаемость
    constructor() {
        makeAutoObservable(this)
    }

    // Добавляем
    editTasksStore(attr, value) {
        this.tasks[attr] = value;
    }

    // Метод для полной замены объекта 
    setTasks(newTasksData) {
        this.tasks = [...newTasksData];
    }

    // Метод для добавления или замены completed_by_current_user по id
    updateCompletedStatusById(id, completed) {
        const taskIndex = this.tasks.findIndex(task => task.id === id);
        if (taskIndex !== -1) {
            this.tasks[taskIndex].completed_by_current_user = completed;
        }
    }



    //Функция для запроса с сервера списка заданий
    async taskList() {
        const apiUrl = config.apiBaseUrl + '/api/api/v1/tasks';
        const token = PlayerStore.player.token; // Получаем токен

        // Заголовки запроса
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Добавляем токен в заголовок Authorization
        };

        try {
            const response = await axios.get(apiUrl, { headers });
            // Используем экземпляр tasksStore для вызова setTasks
            this.setTasks(response.data); // Исправлено с TasksStore на tasksStore
            console.log('СПИСОК ЗАДАНИЙ !!!!!!!!!!!!!!: ', JSON.stringify(this.tasks, null, 1));
        } catch (error) {
            if (error.response) {
                // Сервер ответил статусом, который выходит за пределы диапазона 2xx
                console.error('Ошибка:', error.response.data);
            } else if (error.request) {
                // Запрос был сделан, но ответ не был получен
                console.error('Ошибка запроса:', error.request);
            } else {
                // Ошибка при настройке запроса
                console.error('Ошибка:', error.message);
            }
        }
    }

    //Забрать награду
    taskCollectReward = (id) => {
        console.log('id: ', id);
        const apiUrl = config.apiBaseUrl + '/api/api/v1/taskCollectReward';
        const token = PlayerStore.player.token; // Получаем токен
        const user_id = PlayerStore.player.idchat; // Получаем id пользователя
        const chat_id = PlayerStore.player.idchat; // Получаем id пользователя

        // Заголовки запроса
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Добавляем токен в заголовок Authorization
        };
        //Отправляемые даныне
        const dataRec = {
            'id_task': id,
            'user_id': user_id,
            'chat_id': chat_id
        };

        axios.post(apiUrl, dataRec, { headers })
            .then(response => {
                console.log('ЗАБРАТЬ НАГРАДУ: ', JSON.stringify(response.data, null, 1));
                //setTask(response.data.task);
                this.notifyTaskUpdate(response.data.task);
                if (response.data.error) {
                    toast.error(response.data.message, {
                        position: "bottom-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                        transition: Slide,
                    });
                }
                if (!response.data.error) {
                    toast.success(response.data.message, {
                        position: "bottom-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                        transition: Slide,
                    });
                    //setTimeout(() => {
                    //    closePanel(); // Обновляем данные через 3 секунды
                    //}, 1000);
                }
            })
            .catch(error => {
                if (error.response) {
                    // Сервер ответил статусом, который выходит за пределы диапазона 2xx
                    console.error('Ошибка 2xx:', error.response.data);
                } else if (error.request) {
                    // Запрос был сделан, но ответ не был получен
                    console.error('Ошибка запроса:', error.request);
                } else {
                    // Ошибка при настройке запроса
                    console.error('Ошибка:', error.message);
                }
            });
    }

    //Подтверждение прохождения задания
    taskConfirm = (id) => {
        console.log('id: ', id);
        const apiUrl = config.apiBaseUrl + '/api/api/v1/taskcheck';
        const token = PlayerStore.player.token; // Получаем токен
        const user_id = PlayerStore.player.idchat; // Получаем id пользователя
        const chat_id = PlayerStore.player.idchat; // Получаем id пользователя

        // Заголовки запроса
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Добавляем токен в заголовок Authorization
        };
        //Отправляемые даныне
        const dataRec = {
            'id_task': id,
            'user_id': user_id,
            'chat_id': chat_id
        };

        axios.post(apiUrl, dataRec, { headers })
            .then(response => {
                console.log('ПОДТВЕРЖДЕНИЕ ПРОХОЖДЕНИЯ ЗАДАНИЯ: ', JSON.stringify(response.data, null, 1));
                //setTask(response.data.task);
                this.notifyTaskUpdate(response.data.task);

            })
            .catch(error => {
                if (error.response) {
                    // Сервер ответил статусом, который выходит за пределы диапазона 2xx
                    console.error('Ошибка 2xx:', error.response.data);
                } else if (error.request) {
                    // Запрос был сделан, но ответ не был получен
                    console.error('Ошибка запроса:', error.request);
                } else {
                    // Ошибка при настройке запроса
                    console.error('Ошибка:', error.message);
                }
            });
    }

    //Подтверждение подписки
    telegramConfirm = (id, check) => {
        console.log('id: ', id, 'check: ', check);
        const apiUrl = config.apiBaseUrl + '/api/api/v1/telegramconfirm';
        const token = PlayerStore.player.token; // Получаем токен
        const user_id = PlayerStore.player.idchat; // Получаем id пользователя

        // Заголовки запроса
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Добавляем токен в заголовок Authorization
        };
        //Отправляемые даныне
        const dataRec = {
            'id_task': id,
            'chat_id': check,
            'user_id': user_id
        };

        axios.post(apiUrl, dataRec, { headers })
            .then(response => {
                console.log('ПОДТВЕРЖДЕНИЕ ПОДПИСКИ: ', JSON.stringify(response.data, null, 1));
                if (response.data.data.result.status !== 'left' && response.data.data.ok === true) {
                    console.log('response.data.data.ok: ', JSON.stringify(response.data.data, null, 1));
                    TasksStore.updateCompletedStatusById(response.data.task.id, true);//Изменяем стор если прошёл задание

                    toast.success('SUCCESS!', {
                        position: "bottom-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                        transition: Slide,
                    });
                    //setTimeout(() => {
                    //    closePanel(); // Обновляем данные через 3 секунды
                    //}, 1000);

                }

            })
            .catch(error => {
                if (error.response) {
                    // Сервер ответил статусом, который выходит за пределы диапазона 2xx
                    console.error('Ошибка 2xx:', error.response.data);
                } else if (error.request) {
                    // Запрос был сделан, но ответ не был получен
                    console.error('Ошибка запроса:', error.request);
                } else {
                    // Ошибка при настройке запроса
                    console.error('Ошибка:', error.message);
                }
            });
    }

    //Отправка формы с BID 
    submitForm = (value, task_id) => {

        const apiUrl = config.apiBaseUrl + '/api/api/v1/tasksubmitform';
        const token = PlayerStore.player.token; // Получаем токен
        const user_id = PlayerStore.player.idchat; // Получаем id пользователя
        console.log('value: ', value, 'task_id: ', task_id, 'idchat: ', user_id);

        // Заголовки запроса
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Добавляем токен в заголовок Authorization
        };
        //Отправляемые даныне
        const dataRec = {
            'task_id': task_id,
            'user_id': user_id,
            'bybit_link': value,
        };

        axios.post(apiUrl, dataRec, { headers })
            .then(response => {
                console.log('ОТПРАВКА ФОРМЫ: ', JSON.stringify(response.data, null, 1));
                if (response.data.status == 'success') {
                    toast.success(response.data.message, {
                        position: "bottom-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                        transition: Slide,
                    });

                    //setTimeout(() => {
                    //    closePanel(); // Обновляем данные через 3 секунды
                    //}, 1000);
                }
                if (response.data.status == 'error') {
                    toast.success(response.data.message, {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                        transition: Slide,
                    });
                }
            })
            .catch(error => {
                if (error.response) {
                    // Сервер ответил статусом, который выходит за пределы диапазона 2xx
                    console.error('Ошибка отправки формы 2xx:', error.response.data);
                } else if (error.request) {
                    // Запрос был сделан, но ответ не был получен
                    console.error('Ошибка отправки формы:', error.request);
                } else {
                    // Ошибка при настройке запроса
                    console.error('Ошибка отправки формы:', error.message);
                }
            });
    }

}

const tasksStore = new TasksStore();
export default tasksStore;
